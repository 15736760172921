import { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import PinMe from '../PinMe/PinMe'
import ColourPicker from '../ColourPicker/ColourPicker'
import { SET_DOCUMENT_COLOUR } from '../../graphql/document'
import { assetFilePath } from '../../utilities'
import classNames from "classnames"
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux';

interface FolderBoxProps {
    document: any,
    hidePin?: boolean;
    hideColourSelection?: boolean;
}

export default function FolderBox(props: FolderBoxProps) {
    const { hidePin, document, hideColourSelection } = props

    const [colour, setColour] = useState('white')
    const currentUser = useSelector((state: any) => state.currentUser.user);
    const { folder, unique_id, title, global_pinned } = document;

    const [saveColour] = useMutation(SET_DOCUMENT_COLOUR);

    const url = `/documents/${folder ? 'folder' : 'file'}/${unique_id}`

    const canUnpin = (global_pinned && currentUser.admin) || !global_pinned;

    useEffect(() => {
        if (document.colour) {
            setColour(document.colour)
        }
    }, [])

    const handleSetDocumentColour = () => {
        saveColour({
            variables: { input: {document_id: document.id, colour, global: global_pinned} }
        })
    }

    return (
        <article 
            id={document.id}
            data-folder={folder ? 'true' : 'false'}
            data-folder-colour={colour} className={classNames("FolderBox relative sm:w-[11rem] w-[48%] h-[150px]  border rounded-md bg-no-repeat bg-cover", {
            "foldersvg bg-gray-200": folder,
            [`block-colour--${colour}`]: !folder && colour !== 'white'
        })}>
            <header>
                {canUnpin && !hidePin && (
                    <div className="leading-[0] absolute left-2 top-2 z-10">
                        <PinMe tinyIcon directUnpin document={ document } />
                    </div>
                )}

                {canUnpin && !hideColourSelection && (
                    <div className="absolute right-2 top-1">
                        <ColourPicker 
                            selectedColour={ colour }
                            setColour={ setColour }
                            onFormSubmit={ handleSetDocumentColour}
                        />
                    </div>
                )}
                
            </header>
             <Link to={url} title={ title } className="absolute flex flex-col items-center justify-center overflow-hidden top-4 bottom-0 left-0 right-0">
                
               {!folder && (
                    <object>
                        <div className='relative overflow-hidden'>
                            <img className='h-[70px] object-contain' src={ assetFilePath(document.files[0]?.extension) } alt={ title } />
                        </div>
                    </object>
                )}
                <footer className="flex flex-col items-center justify-center p-1 w-full">
                    <div className={classNames("text-xs font-medium text-center truncate  w-full", {
                        "!text-sm": folder
                    })}>{ title }</div>
                </footer>
               
             </Link>
        </article>
    )
}

