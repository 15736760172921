import { useEffect, useState } from 'react'
import { useDidUpdateEffect } from '../../hooks'
import { useLazyQuery } from '@apollo/client'
import { GET_BREADCRUMB } from '../../graphql/breadcrumb'

interface BreadcrumpProps {
    unique_id: string
}

export default function Breadcrumb(props: BreadcrumpProps): JSX.Element {
    const { unique_id } = props
    
    const [getBreadcrumb, { loading, error, data }] = useLazyQuery(GET_BREADCRUMB);
    // const { loading, error, data, } = useQuery(GET_BREADCRUMB, {
    //     variables: { input: { unique_id } }
    // });

    // console.log(loading, error, data)

    // useEffect(() => {
    //     console.log('GET BREADCRUMB NOW', unique_id)
    //     getBreadcrumb({
    //         variables: { input: { unique_id} }
    //     })
    // }, [unique_id])

    return <div></div>
    return (
        <small className='block mt-1'>
            foo / bar / one
        </small>
    )
}