import EditFolder                                                                       from '@/pages/Documents/DocumentFolders/EditFolder'
import NewFolder
                                                                                        from '@/pages/Documents/DocumentFolders/NewFolder'
import { FolderIcon, FolderOpenIcon, DocumentIcon, ChevronRightIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import { Link }                                                                         from 'react-router-dom'
import { useEffect, useState, useRef }                                                  from 'react'
import './NavButton.scss'
import DocumentLocationSelection                                                        from '../../components/DocumentLocationSelection/DocumentLocationSelection'
import PinMe                                                                            from '../../components/PinMe/PinMe'

interface NavButtonProps {
  // The actual ID for the document and NOT the unique_id
  document: any;
}

export default function NavButton( props: NavButtonProps ) {
  const titleBtn                        = useRef( null )
  const { document }                    = props
  const [ isOpen, setIsOpen ]           = useState( false )
  const [ offsetWidth, setOffsetWidth ] = useState( 0 )

  const toggleCollapse = () => setIsOpen( !isOpen )

  useEffect( () => {
    setOffsetWidth( titleBtn.current.offsetWidth )

    const handleClickOutside = ( e: any ) => {
      if ( titleBtn.current && !titleBtn.current.contains( e.target ) ) {
        setIsOpen( false )
      }
    }

    window.document.addEventListener( 'click', handleClickOutside, true )

    return () => {
      window.document.removeEventListener( 'click', handleClickOutside, true )
    }
  }, [isOpen, offsetWidth] )

  return (
   <div className="flex items-center mb-4">
      <div className="flex items-center relative flex-1">
      <button
        ref={ titleBtn }
        type="button"
        className="p-0 flex items-center text-lg bg-gray-100 hover:bg-gray-200 rounded-lg px-4 py-2"
        onClick={ toggleCollapse }
      >
        <FolderOpenIcon className="w-6 h-6" />

        <span className="mx-2">{ document?.title ?? 'Home' }</span>

        <ChevronRightIcon className="w-3 h-4" />
      </button>

      <div role="menu" className={ isOpen ? 'dropdown-menu' : 'hidden' } style={ { left: `${ offsetWidth }px` } }>
        <Link to={ `/documents/folder/new/${ document ? document.unique_id + '/new' : '' }` }>
          <button type="button" role="menuitem" className="dropdown-item">
            <FolderIcon className="w-4 h-4 text-gray-500 mr-2" />
            <span className="pl-1 text-left">Add new folder</span>
          </button>
        </Link>

        { document &&
          <Link to={ `/documents/folder/edit/${ document.unique_id }/edit` }>
            <button type="button" role="menuitem" className="dropdown-item">
              <PencilSquareIcon className="w-4 h-4 text-gray-500 mr-2" />
              <span>Edit folder</span>
            </button>
          </Link>
        }
      </div>
      </div>
      
      {document ? (
        <div className='flex items-center gap-x-2'>
          <DocumentLocationSelection document={ document } />
          <PinMe document={ document } />
        </div>
      ) : null}
   </div>
  )
}
