export const ForumTopic = () => {
    return (
        <article className="ForumTopic p-3">
            <header className="mb-2 flex items-center justify-between">
                <h3 className="text-blue-500  font-medium">The Topic Name</h3>
                <div className="text-sm">
                    <small className="block">Last active</small>
                    <time className="font-medium">4 years ago</time>
                </div>
            </header>
            <div className="leading-0">
                <strong className="block text-base">6 Question</strong>
                <small className="block text-gray-500 font-medium">Added 4 years ago by Admin, HQ</small>
            </div>
        </article>
    )
}