import { ForumTopics } from "../../components/ForumTopics/ForumTopics"
import { ForumQuestions} from "../../components/ForumQuestions/ForumQuestions"
import MainLayout from "../../layouts/MainLayout"
import { Button } from "../../components/Button"
import { Link } from "react-router-dom"

export const ForumPage = () => {
    return (
        /** May change this to use a ForumLayout instead? */
        <MainLayout>
            <div className="ForumPage flex-grow lg:grid lg:grid-cols-[1fr_30%] gap-4 max-w-screen-xl w-full mx-auto mt-6">
                
                <section className="flex-1 bg-white border rounded-md p-4 space-y-4">
                    <header className="space-y-3">
                        <h1 className="text-xl font-medium">Welcome to Banana Moon Forum</h1>
                        <p className="text-sm text-gray-600">
                            Here you can interact with the whole of the Banana Moon network. Use this online bulletin board to start conversations and discussions, 
                            share tips or seek advice, and engage with ongoing topics. Head Office may also start some topics, so keep an eye out for new posts!
                        </p>
                        <div className="flex sm:flex-row flex-col gap-3 items-center justify-between">
                            <Link to={'/forum/ask-a-question'} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                <span>Ask a question</span>
                            </Link>

                            {/** Extract to a component later */}
                            <div className="inline-flex rounded-md shadow-sm" role="group">
                                <button type="button" className="px-4 py-2 sm:text-sm text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                    All
                                </button>
                                <button type="button" className="px-4 py-2 sm:text-sm text-xs font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                    Recent
                                </button>
                                <button type="button" className="px-4 py-2 sm:text-sm text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                    Unanswered
                                </button>
                            </div>

                        </div>

                    </header>

                    <ForumQuestions />
                </section>
                
                {/** We're hiding this on medium view. TBH, not sure of this is important to display */}
                <section className="flex-1 lg:block hidden">
                    <ForumTopics />
                </section>

            </div>
        </MainLayout>
    )
}