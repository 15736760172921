// @ts-nocheck
import { useMutation }                                                            from '@apollo/client'
import { TrashIcon }                                                              from '@heroicons/react/24/outline'
import { useState }                                                               from 'react'
import { useSelector }                                                            from 'react-redux'
import Datepicker                                                                 from 'react-tailwindcss-datepicker'
import { Button, TextField }                                                      from '../../../components'
import {
  LoadingEllipsis
}                                                                                 from '../../../components/Loaders/Loaders'
import { Switch }                                                                 from '../../../components/Switch'
import { CREATE_DOCUMENT_FOLDER, DELETE_DOCUMENT_FOLDER, UPDATE_DOCUMENT_FOLDER } from '../../../graphql/document'

export default function FolderForm( props: any ) {
  const { parentId, document } = props
  const currentUser            = useSelector( ( state: any ) => state.currentUser.user )

  const [ updateDocumentFolder, { loading: updating } ] = useMutation( UPDATE_DOCUMENT_FOLDER, {
    onCompleted: () => window.location.href = '/documents'
  } )

  const [ createDocumentFolder, { loading: creating } ] = useMutation( CREATE_DOCUMENT_FOLDER, {
    onCompleted: ( { createDocumentFolder } ) => navigateAway( createDocumentFolder.data )
  } )

  const [ deleteDocumentFolder, { loading: deleting } ] = useMutation( DELETE_DOCUMENT_FOLDER, {
    onCompleted: () => window.location.href = '/documents'
  } )

  const [ publish, setPublish ] = useState( document ? !document.draft : false )
  const handlePublishChange     = ( newValue: any ) => setPublish( newValue )

  const [ pinGlobally, setPinGlobally ] = useState( document?.global_pinned ?? false )
  const handlePinGloballyChange         = ( newValue: any ) => setPinGlobally( newValue )

  const [ title, setTitle ] = useState( document?.title ?? '' )
  const handleTitleChange   = ( newValue: any ) => setTitle( newValue )

  const [ aboutText, setAboutText ] = useState( document?.about ?? '' )
  const handleAboutTextChange       = ( newValue: any ) => setAboutText( newValue )

  const [ publishDate, setPublishDate ] = useState(
    { startDate: document?.publish ?? null, endDate: document?.publish ?? null }
  )
  const handlePublishDateChange         = ( newValue: any ) => setPublishDate( newValue )

  const navigateAway = ( document: any ) => {
    window.location.href = `/documents/folder/${ document.unique_id }`
  }

  const handleSubmit = ( e: any ) => {
    e.preventDefault()

    const formData: any = {
      folder:        true,
      draft:         !publish,
      title:         title,
      global_pinned: pinGlobally,
      about:         aboutText,
      publish:       publishDate.startDate ? new Date( publishDate.startDate ).toISOString() : null
    }

    if ( document ) {
      formData.unique_id = document.unique_id
      formData.parent_id = document.parent_id

      updateDocumentFolder( { variables: { input: formData } } )
    } else {
      formData.parent_id = parentId
      createDocumentFolder( { variables: { input: formData } } )
    }

    reset()
  }

  const handleDelete = ( e: any ) => {
    e.preventDefault()

    const confirmed = window.confirm( 'Are you sure you want to delete this document?' )
    if ( confirmed ) {
      // Perform the delete operation here
      deleteDocumentFolder( {
                              variables: { input: { unique_id: document?.unique_id } }
                            } )
    }

  }

  const reset = () => {
    setPinGlobally( false )
    setPublish( false )
  }


  return (
    <>
    { ( updating || creating || deleting ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
      { ( !updating || !creating || !deleting ) &&
        <form className="mt-8 p-3" onSubmit={ handleSubmit }>
          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Published</div>
            <div className="w-2/3">
              <Switch
                checked={ publish }
                onChange={ handlePublishChange }
              />
            </div>
          </div>

          { currentUser?.admin && (
            <div className="flex flex-wrap items-center w-full mb-3">
              <div className="w-1/3">Pin Globally</div>
              <div className="w-2/3">
                <Switch
                  checked={ pinGlobally }
                  onChange={ handlePinGloballyChange }
                  helpText="Everyone will see this folder on top of their documents list."
                />
              </div>
            </div>
          ) }

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Title</div>
            <div className="w-2/3">
              <TextField
                isRequired
                label=""
                value={ title }
                name="title"
                type="text"
                onChange={ handleTitleChange }
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">About</div>
            <div className="w-2/3">
              <TextField
                label=""
                value={ aboutText }
                placeholder="Additional information..."
                name="aboutText"
                type="textarea"
                onChange={ handleAboutTextChange }
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Publish Date</div>
            <div className="w-2/3">
              <Datepicker
                useRange={ false }
                asSingle={ true }
                value={ publishDate }
                onChange={ handlePublishDateChange }
              />
            </div>
          </div>


          <div className="flex flex-wrap items-center w-full mt-6 border-t-2 border-gray-300">
            <div className="flex w-1/3 pt-5">
              <Button loading={ creating } submittable label="Submit" onAction={ () => {
              } } />
              { document &&
                <button
                  className="gap-x-2 px-3 py-1.5 text-sm w-full text-white font-medium flex items-center justify-center rounded-full text-center text-sm ml-2 bg-red-700 hover:bg-red-500"
                  onClick={ handleDelete }
                >
                  <TrashIcon className="w-4 h-4" />
                  <span>Delete</span>
                </button>
              }
            </div>
          </div>
        </form>
      }
      </>
  )
}
