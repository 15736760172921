import { FolderIcon, FolderOpenIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import FolderItemChildren                   from './FolderItemChildren'
import { useState }                         from 'react'
import { useSelector }                      from 'react-redux'
import { useParams, Link }                  from 'react-router-dom'
import PinMe                                from '../../../components/PinMe/PinMe'
import DocumentLocationSelection            from '../../../components/DocumentLocationSelection/DocumentLocationSelection'

export default function FolderItem( props: any ) {
  const { page }              = useParams()
  const { folderId }          = props
  const [ isOpen, setIsOpen ] = useState( false )
  const folders               = useSelector( ( state: any ) => state.documents.folders )
  const folder                = folderId ? folders.find( ( item: any ) => item.unique_id == folderId ) : null
  const children              = folderId ? folders.filter( ( item: any ) => item.parent_id == folderId && item.folder ) : []

  const toggleCollapse = () => setIsOpen( !isOpen )

  return (
    <div className="folder-item pl-3">
      <div role="group" className="flex items-center">
        {
          children.length > 0 &&
          <button type="button" className="p-0 flex items-center" onClick={ toggleCollapse }>
            { isOpen ? <ChevronDownIcon className="w-3 h-4" /> : <ChevronRightIcon className="w-3 h-4" /> }
          </button>
        }

        {
          children.length < 1 &&
          <button className="p-0">
            <div className="w-3" />
          </button>
        }

        <div className="relative flex items-center gap-x-2">
          <Link to={ `/documents/folder/${ page ? page + '/' : '' }${ folderId }` }>
            <button type="button" className="pl-1 flex items-center hover:text-blue-secondary-darker">
              { isOpen ? <FolderOpenIcon className="w-5 h-5" /> : <FolderIcon className="w-5 h-5" /> }
              <span className="pl-1 text-left">{ folder?.title }</span>
            </button>
          </Link>
          <div className='flex items-center gap-x-1'>
            <DocumentLocationSelection document={ folder } />
            <PinMe document={ folder } />
          </div>
        </div>
      </div>

      <div className={ isOpen ? 'flex' : 'hidden' }>
        <FolderItemChildren folderId={ folderId } />
      </div>
    </div>
  )
}
