import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Money from 'js-money';
import createAxiosInstance  from '../../utilities/axios';
import {
    MicrophoneIcon,
    ChartPieIcon,
    ListBulletIcon,
    BuildingStorefrontIcon,
    ShoppingCartIcon,
    RectangleStackIcon,
    NewspaperIcon
  } from '@heroicons/react/20/solid';

export default function StatsLinks() {
    const currentUser = useSelector((state: any) => state.currentUser.user);
    const userView = useSelector((state: any) => state.userView);
    const [items, setItems] =  useState([]);

    const getStats = async () => {
        const Api = createAxiosInstance(process.env.REACT_APP_V1_HOST);

        try {
            const { data } = await Api.get(`/api/v2-stats?userView=${userView.userView}`);
            setItems(data);
        } catch (error) {
            console.log("Error getting stats", error)
        }
    }

    const valueMarkup = (item: any) => {
        if (item.type === 'money') {
            const total = formatMoney(item.value);
            return total
        }

        return item.value
    }

    const url = (path: string, v2: boolean) => ( v2 ? path : ( process.env.REACT_APP_V1_HOST + path ) )

    useEffect(() => {
        if (currentUser) {
            getStats()
        }

        console.log(userView)
    }, [currentUser, userView]);

    if (items.length === 0) return


    return (
        <section className="StatsLink flex gap-x-3 overflow-x-auto">
           {items.map((item, index) => (
             <article key={item.label + `_${index}`} className="bg-white rounded-xl md:p-4 p-3">
                <a href={ url(item.path, !!item.v2) } className='flex min-w-content gap-x-3 text-sm font-medium'>
                    <div className="md:w-12 w-10 text-white md:h-12 h-10 rounded-full bg-blue-secondary flex items-center justify-center">
                        {item.icon && (
                            getIcon(item.icon)
                        )}
                    </div>
                    <div className="flex-1 leading-snug">
                        <div className="text-gray-400 whitespace-nowrap">{item.label}</div>
                        <div className="md:text-xl text-lg font-bold">{valueMarkup(item)}</div>
                    </div>
                </a>
             </article>
           ))}
        </section>
    );
}

function getIcon(icon: string) {
    switch (icon) {
        case 'documents':
            return <RectangleStackIcon className="w-5 h-5" />;


        case 'marketing':
            return <ChartPieIcon className="w-5 h-5" />;


        case 'tasks':
            return <ListBulletIcon className="w-5 h-5" />;


        case 'topics':
            return <MicrophoneIcon className="w-5 h-5" />;

        case 'news':
            return <NewspaperIcon className="w-5 h-5" />;


        case 'cart':
        case 'orders':
            return <ShoppingCartIcon className="w-5 h-5" />;

        case 'shop':
            return <BuildingStorefrontIcon className="w-5 h-5" />;

        default:
            return null;
    }
}

function formatMoney(money: any): string {
    let pound = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });

    return pound.format(parseInt(money));
}