import { GET_PINNED_DOCUMENTS } from "../../graphql/document"
import { useQuery } from "@apollo/client"
import FolderBox from "../FolderBox/FolderBox";

export default function PinnedDocuments(props: any) {
    const { loading, error, data } = useQuery(GET_PINNED_DOCUMENTS, {
        // Ebsure PinMe variables are the same!!!
        variables: { input: {perPage: 50} },
        //onCompleted: (data) => console.log(data),
    })

    if (loading || error) return

    const userPins = data?.pinnedDocuments?.data.filter((doc: any) => !doc.global_pinned) ?? []
    const adminPins = data?.pinnedDocuments?.data.filter((doc: any) => doc.global_pinned) ?? []

    return (
        <section className='mb-6 space-y-4'>
           {adminPins.length > 0 && (
                <section>
                    <h4 className='mb-2 font-medium text-sm '>Documents Pinned By Admins</h4>
                    <div className='flex flex-wrap gap-2'>
                        {adminPins.map((doc: any) => <FolderBox key={doc.id} document={ doc } />)}
                    </div>
                </section>
           )}

            {userPins.length > 0 && (
                <section>
                    <h4 className='mb-2 font-medium text-sm '>Your Pinned Documents</h4>
                    <div className='flex flex-wrap gap-2'>
                        {userPins.map((doc: any) => <FolderBox key={doc.id} document={ doc } />)}
                    </div>
                </section>
            )}
        </section>
    )
}