import MainLayout from '../../layouts/MainLayout'

export default function BmPlatformFaq(): JSX.Element {
  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="flex w-full mb-4">
              <header className="mb-4">
                <h1 className="sm:text-2xl text-xl font-bold">BM Platform</h1>
                <p className="text-sm text-gray-500">FAQ</p>
              </header>
            </div>

            <div className="flex flex-col w-full text-sm leading-normal">
              <h2 className="font-medium text-3xl mb-4">Customer Support:</h2>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">Blossom not loading?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Using Blossom in Internet Explorer?</li>
                <li>Please open it in Google Chrome or Safari as Blossom works best on these browsers.</li>
                <li>Google Chrome and Safari are less prone to viruses and are generally quicker internet browsers.</li>
                <li>You might want to also set Google Chrome or Safari as the default browser.</li>
                <li>What can you see on your screen?</li>
                <li>Can you see the buffer/spinner?</li>
                <li>Have you got the correct URL?</li>
                <li>Is your browser and software up to date on your device?</li>
                <li>Check that your Wi-Fi is working – load another website.</li>
                <li>Check the speed of your internet at fast.com.</li>
                <li>If message says ‘Blossom is unavailable’, you are using Internet Explorer and need to change the web browser to Chrome. This message only appears on Internet Explorer.</li>
              </ul>

              <h3 className="font-medium text-xl mb-2">Earlier</h3>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>What time of day?</li>
                <li>Which pages, or everything?</li>
                <li>
                  “Okay, we are going to keep an eye on this. How is the speed now?”
                  <ul className="w-full list-[circle] pl-8">
                    <li>“Yes, working fine.”</li>
                  </ul>
                </li>
                <li>Please keep a log if you can and call us at the time of issue.</li>
                <li>Otherwise, keep a log of this and this is something we can track.</li>
                <li>This could be the internet connection speed.</li>
                <li>I suggest going on a computer and opening a new window. Search BBC and see if there is a difference.</li>
                <li>Internet can dip throughout day – keep us updated on how often this is happening.</li>
              </ul>

              <h3 className="font-medium text-xl mb-2">Now</h3>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>What can you see on your screen?</li>
                <li>Can you see the buffer/spinner wheel?</li>
                <li>Check the app loads off another device.</li>
              </ul>

              <h3 className="font-medium text-xl mb-2">If loads at our end</h3>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>What can you see – spinning wheel or is there a message?</li>
                <li>Message – ‘Blossom is unavailable’. “Can you let me know what the exact wording is?”</li>
                <li>Can you tell me which device you are using?</li>
                <li>Can you tell me which browser you are using? Blossom works best with Google Chrome and Safari. It's a faster browser and less prone to viruses.</li>
                <li>I just want to check a few things with you – what does the web browser have (name colours)?</li>
                <li>What does it say in your URL bar? /Accessforbidden – typed in URL correctly.</li>
                <li>If you send link to email address and it is clicked, you are taken to page ‘Blossom currently not available’.</li>
                <li>It is very likely that the default browser is Internet Explorer – support changing to Google Chrome.</li>
                <li>Support to download Google Chrome – set default.</li>
                <li>Is the nursery a chain (forbidden access)?</li>
                <li>Support to clear cache, cookies and history.</li>
                <li>Check Google Chrome is up to date.</li>
                <li>Google Play store – search for Google Chrome.</li>
                <li>See an option uninstall or update.</li>
                <li>Click ‘Update’.</li>
              </ul>

              <h3 className="font-medium text-2xl mb-2">Potential bug fix</h3>

              <br/>

              <h4 className="font-medium text-xl mb-2 underline">If you are using Chrome:</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Open Chrome.</li>
                <li>Click the three dots on the right side of the screen.</li>
                <li>Click on ‘History’.</li>
                <li>Then click ‘Clear browsing data’.</li>
                <li>This will then show you your clear browsing data.</li>
                <li>Click ‘Advance’ just under the title ‘Clear browsing data’.</li>
                <li>Select the time range for ‘All time’.</li>
                <li>Please tick the follow boxes only: ‘Browsing history’, ‘Cookies and other site data’ and ‘Cached images and files’.</li>
                <li>Click ‘Clear data’.</li>
                <li>Once this is cleared, enter in your nursery URL in the search bar and sign in to Blossom. You will then need to authorise your device.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">If you are using Safari:</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Open settings.</li>
                <li>Scroll down to Safari on the left-hand side.</li>
                <li>Click on ‘Safari’.</li>
                <li>Then ‘Clear History and Website Data’.</li>
                <li>Click ‘Clear’.</li>
                <li>Once this is cleared, enter in your nursery URL in the search bar and sign in to Blossom. You will then need to authorise your device. This can be done by you manager.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Is Google Chrome updated?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Open Google Play store.</li>
                <li>In the search bar please search ‘Google Chrome’.</li>
                <li>You will then see an option to uninstall or update.</li>
                <li>Click ‘Update’.</li>
                <li>Once this is complete, Google Chrome will be on the latest version. Please ensure you check for updates regularly.</li>
              </ul>
              <strong>Top tip – please keep your Chrome up to date.</strong><br />
              <br />

              <h4 className="font-medium text-xl mb-2 underline">How do I add Blossom to my device?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Is Blossom saved to the home screen?</li>
                <li>Android or iPad?</li>
                <li>Which nursery are you calling from?</li>
                <li>Do you know your URL?</li>
                <li>Tell them what the URL is.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Authorising a device</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Log in using your username and ID.</li>
                <li>Pink pop-up in the right-hand corner: ‘Authorising device’.</li>
                <li>Pink pop-up at the right-hand side of the screen.</li>
                <li>Click on authorising device.</li>
                <li>Enter the name of the device – this can be the room name or number of device etc.</li>
                <li>Select a staff member – a manager can authorise a device without a pin.</li>
                <li>You should be able to see list of devices.</li>
                <li>Your device is now authorised.</li>
              </ul>
              <strong>Any manager can authorise a device.</strong><br />
              <strong>Practitioner and Room Leader has to log in on an authorised device. </strong><br />
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Resetting a password</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>On the login page, please click ‘Forgotten your password’.</li>
                <li>Enter your email address.</li>
                <li>Click ‘Request password reset’.</li>
                <li>You will then be sent an email called ‘Password reset’.</li>
                <li>Open and click ‘Reset password’.</li>
                <li>Please enter a new password.</li>
                <li>Then click ‘Change password’.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Resending a password invitation</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Go to the organisation URL for the setting.</li>
                <li>Click on staff – you can see which users have been added to the organisation view and what their status is. If their status is pending, click the pencil to edit their profile, and you will then see an option to resend the invitation.</li>
                <li>Click this button (the Director of the nursery group can do this on their side too). This will resend an email invitation to the user. Once they accept, the status updates (you will not see ‘pending’ next to their name – you may have to refresh your page). The user will then have access to the organisation.</li>
                <li>Please note these are the same steps for resending an invitation to a staff member so that they can set their password and log in.</li>
                <li>We sometimes have to explain this to Nursery Managers as staff members might say they did not receive an email to set their password or might not be able to find the password.</li>
                <li>Nursery Managers can resend invitations to their staff members.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">How do I get parent app started?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Once the family contacts have been added for the children, you can turn on access for the parent app. Here is a short video on how to share information with Blossom through our parent app: <a href="https://www.youtube.com/watch?v=j7c5BPN7Lgk&feature=youtu.be" className="text-blue-secondary" target="_blank">https://www.youtube.com/watch?v=j7c5BPN7Lgk&amp;feature=youtu.be</a>
                </li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">What does ‘pending’ mean next to a staff’s profile? How do I complete that?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>If a staff member has ‘pending’ next to their name, this means that their password has not been set. “I cannot see you on the list of staff for West Ealing; however, you are set as the Director of the organisation, which means that your user details are on the organisation level staff list. Hope this helps.”</li>
              </ul>

              <h4 className="font-medium text-xl mb-2 underline">Once I add staff, does it mean that they can have access to Blossom at home? Would they receive an email to create an account and password? I will be mindful if they will have access to confidential information at home.</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Staff only have access to Blossom on devices you have authorised. This means that they cannot access Blossom from home or from their mobiles without your permission.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Is the start date the date we started using the system or the date the child started the nursery?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Input the child’s start date for when they started the nursery.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Moving a child to another nursery</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Request the PDF of the child’s learning journey, observations and reports. We will send a copy of this to yourselves and executive house site.</li>
                <li>We would then ask the staff at 'new/other nursery' to add the child to their nursery and their baseline to the framework. They can then continue using Blossom as normal.</li>
                <li>Please let me know the name of the child when they are ready to move nurseries and I will get this sorted for you.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">What should nurseries do at the end of term to provide parents with access to their child's learning journey?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Before the end of term we advise nurseries to request the full PDF of the learning journey from us (Observations and Formative reports). As soon as a child is archived on Blossom, access to the parent app for this child is revoked for all contacts associated to that child.</li>
              </ul>

              <br />

              <hr className="my-4"/>

              <h2 className="font-medium text-3xl mb-4">Sales:</h2>

              <h3 className="font-medium text-xl mb-2">What can the parents see exactly?</h3>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Any approved observations that have been sent to parents</li>
                <li>Any observations sent in from home</li>
                <li>
                  The daily diary:
                  <ul className="w-full list-[circle] pl-8">
                    <li>Sleeps</li>
                    <li>Nappies</li>
                    <li>Meals/snacks/bottles</li>
                    <li>Activities</li>
                  </ul>
                </li>
                <li>Their profile</li>
                <li>Child’s ‘all about me’ section.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Security</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>The software is cloud-based and we use AWS, Amazon Web Services.</li>
                <li>The data is yours and you can access this at any time.</li>
                <li>We are GDPR-compliant and have gone through rigorous internal and external programs.</li>
                <li>We have gone one step further and, using our Early Years knowledge, have also created a GDPR Guide for Nurseries & Staff to explain the guidelines and help you be compliant alongside Blossom.</li>
                <li>We are firm believers in creating the platform around your existing policies and procedures.</li>
                <li>You should not have to change what you do to fit around a software.</li>
                <li>With our expert training, we work with nurseries to help implementation and to best understand your requirements in detail.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">Who can view the child’s profile?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>All members of staff registered in Blossom can view the profile. Only selected staff can edit and amend information across the platform.</li>
                <li>Parents can submit the ‘All about me’ section, which is visible on the parent app.</li>
                <li>Everything else will require you to send and share.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">Can you use it for supervisions?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Yes, we recommend using the observation tracker to observe how many observations were being completed, by what member of staff, or room or time period.</li>
                <li>This was useful when looking into their professional development as you are able to see staff members’ observation preference (lots in EAD and none in Maths).</li>
                <li>Also looking into Key Groups, do the staff need training etc.?</li>
                <li>Also look into how the staff members use the app. For example, my Pre-school room would use the parent app to share available days they had. This boosted occupancy levels and you may want to personally reward that team member for the idea and implementation in their reviews.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">Where do you store the devices?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Some of our nurseries use a locked drawer or cupboard with a signing in and out document.</li>
                <li>Others keep them in a designated place in the rooms throughout the day.</li>
                <li>Some level the responsibilities with the Room Leaders.</li>
                <li>You will need to create a clear policy and procedure for your staff team to adhere to.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">What about staff stealing them?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>This comes down to your internal policies.</li>
                <li>Also as we are a web-based app, your data is secure, you and we can unauthorise the devices remotely ensuring no one can access anything.</li>
                <li>Nursery story about being broken into.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">Is the information emailed automatically?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>The Banana Moon platform and the parent app can be set up for live updates to the app or can have an approval process.</li>
                <li>This is to your preference.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">Can only the Key Person see child?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>No, we want to be inclusive so all staff can observe and access all children.</li>
                <li>Good practice.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">How do I define the transition between the two?</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Just reference in the paper learning journey that you have ended reporting on paper and started on the Banana Moon system from X date. You can download a document for this from the Hub.</li>
              </ul>

              <br />

              <hr className="my-4" />

              <h2 className="font-medium text-3xl mb-4">Parents:</h2>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">Downloading photos of children</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>At the moment, photos cannot be saved directly from the Blossom parent app. If parents would like to save any photos from the diary, we recommend they ask the nursery to send the diary via email as well. Here you will receive a link to view your child's diary online, where you'll be able to save any photos straight to your computer or phone.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">Parent has not received the email to download the app</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>If someone calls and says that a parent hasn’t been sent the email to download the parent app, check they have access. If they do, edit and turn off access, then save and turn it back on again.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">Download a learning journey on the parent app</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>The nursery will need to request a PDF of your child's learning journey. This will include all observations and formative reports for your child. They will be able to email this to you directly. Unfortunately we cannot send this to you for security reasons.</li>
              </ul>

              <br />

              <h4 className="font-medium text-xl mb-2 underline">How to add the home screen on Android</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Email/phone script:</li>
                <li>
                  “Please follow this process to add Banana Moon parent app to your home screen:
                  <ul className="w-full list-[circle] pl-8">
                    <li>Open Google Chrome</li>
                    <li>Type in your unique URL: this will be issued to you</li>
                    <li>Click on the three dots in the top right corner</li>
                    <li>Select add to home screen.”</li>
                  </ul>
                </li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Parent has lost access to the email address to log into the system</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Delete contact information and create a new contact for yourself. As you have lost access to your email address a new email account needs to be created.</li>
                <li>Blossom cannot make this change as it is emergency contact information and the nursery should be responsible for any changes made to the child's and contact’s profile.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Parent wants to change their email address</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Once a parent has logged into the parent app, their email address cannot be changed on the nursery’s access to Blossom. They can change their email address in their profile on the app – this can be found in the side menu: My profile.</li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">Clicking on the link from the parent app but not being logged in</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>
                  Try the following steps to get you logged into the Blossom parent app:
                  <ul className="w-full list-[circle] pl-8">
                    <li>Close the app or Play store by either clicking on the recent app or double-tapping the home button and swiping to kill the app.</li>
                    <li>Open the Blossom parent app and enter the email address you have registered with your nursery.</li>
                    <li>You will receive a magic link email to log into Blossom automatically. Please note the magic link email expires after 24 hours for security or after its successful use. Open the email on your mobile and click on ‘Sign in’ – this should launch the app and log you in.</li>
                    <li>If this email redirects you to the app or Play store again, could you please click the home button and go into your app and open Blossom. This may take a few seconds to log in.</li>
                    <li>If this email redirects you back to either the login or magic link screens, please close the Blossom app and repeat step 3.</li>
                  </ul>
                </li>
              </ul>
              <br />

              <h4 className="font-medium text-xl mb-2 underline">I am not receiving any parent app magic link emails</h4>
              <ul className="w-full list-disc pl-8 mb-4">
                <li>Check in the child's profile (contacts) to see if the same email address as what is registered has been entered. Check the logs in Mailgun to see if the email has been sent. If it has bounced back or there is a message, search in suppressions and delete the email.</li>
                <li>Retry if necessary.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
