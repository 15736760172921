import { Image } from "../Image";
export default function LatestProduct(props: any) {
    const { name, price, url, image_full_url } = props;
    return (
        <article className="LatestProduct bg-white hover:shadow-lg rounded-xl cursor-pointer p-3">
            <a href={process.env.REACT_APP_V1_HOST + url} className="flex items-center gap-x-4" aria-label={ name }>
                <Image 
                    defaultSrc="https://www.bananamoon-longford.co.uk/storage/gallery/LAD_5411-min.jpg"
                    src={ image_full_url }
                    alt={ name }
                    klass={ 'w-12 h-12 rounded-md object-cover '}
                />
                <div className="flex flex-1 font-medium items-center justify-between">
                    <div>{ name }</div>
                    <div>£{ price }</div>
                </div>
            </a>
        </article>
    )
}