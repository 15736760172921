import { createSlice } from '@reduxjs/toolkit'

export const integrationSlice = createSlice({
  name: 'integration',
  initialState: {
    isConnecting: false,
  },
  reducers: {
    setConnecting: (state, action) => {
      state.isConnecting = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setConnecting } = integrationSlice.actions

export default integrationSlice.reducer