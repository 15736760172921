
import { Switch as Swwitch } from '@headlessui/react';

interface SwitchProps {
    checked: boolean;
    onChange: (event: any) => void;
    helpText?: string;
}

export const Switch = (props: SwitchProps) => {
    const { helpText, checked, onChange } = props;
    return (
        <div className="">
            <Swwitch
                checked={checked}
                onChange={onChange}
                className={`${checked ? 'bg-blue-600' : 'bg-gray-400'}
                relative inline-flex h-[22px] w-[38px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                <span className="sr-only">Use setting</span>
                <span
                aria-hidden="true"
                className={`${checked ? 'translate-x-4' : 'translate-x-0'}
                    pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Swwitch>
            {helpText && <p className='text-xs mt-1 text-gray-500'>{ helpText } </p>}
        </div>
    )
}