import { 
  ChartPieIcon, 
  ListBulletIcon, 
  EllipsisHorizontalIcon,
  NewspaperIcon,
  MicrophoneIcon,
  RectangleStackIcon,
  ShoppingCartIcon
} from '@heroicons/react/24/solid' 
import { Link } from 'react-router-dom'

export default function NotificationItem(props: any) {
  const { title, group, unique_id, created_at } = props;

  const commonClassName = `flex px-2 my-2 py-2 hover:bg-gray-100 mx-2 rounded-md text-regular select-none leading-5 hover:text-black no-underline flex-1 w-full ${props.readAt ? 'text-gray-500' : 'text-black'}`;

  const editButtonMarkup = (
    <span className="cursor-pointer border right-3 absolute top-6 z-10 opacity-0 group-hover:opacity-100 h-8 w-8 rounded-full shadow-md bg-white flex items-center justify-center hover:bg-gray-100">
      <EllipsisHorizontalIcon className="w-5 h-5 text-black" />
    </span>
  )


  const initialMarkup = (
    <div className={'text-white relative shadow flex items-center justify-center bg-blue-600 ${getTheme(IconMeta?.Theme)} rounded-full w-10 h-10 mr-2'}>
      { getIcon(group) }
    </div>
  );

  const obj: any = getNotificationUrl(group, unique_id);


  return (
    <article className="relative group flex items-center">
      {obj.v2 ? (
          <Link to={obj.url} title={title} className={commonClassName}>
              {initialMarkup}
              <div className="flex flex-1 items-center flex-1">
                  <div className="flex-1 p-1">
                      <div className="w-full max-w-[200px] !text-sm truncate font-medium">{title}</div>
                      <small className="uppercase text-xs">{group}</small>
                  </div>
              </div>
          </Link>
      ) : (
          <a href={process.env.REACT_APP_V1_HOST + obj.url} title={title} className={commonClassName}>
              {initialMarkup}
              <div className="flex flex-1 items-center flex-1">
                  <div className="flex-1 p-1">
                      <div className="w-full max-w-[200px] !text-sm truncate font-medium">{title}</div>
                      <small className="uppercase text-xs">{group}</small>
                  </div>
              </div>
          </a>
      )}
      {editButtonMarkup}
    </article>
  )
}

function getIcon(icon: string) {
  // Switch case on icon type
  switch (icon) {
  
      case 'news':
          return <NewspaperIcon className="w-5 h-5" />;


      case 'document':
          return <RectangleStackIcon className="w-5 h-5" />;

      case 'marketing':
          return <ChartPieIcon className="w-5 h-5" />;


      case 'task':
          return <ListBulletIcon className="w-5 h-5" />;


      case 'topic':
          return <MicrophoneIcon className="w-5 h-5" />;

      case 'shop_product':
          return <ShoppingCartIcon className="w-5 h-5" />;
  
      default:
          return null;
  }
}

function getNotificationUrl(group: string, unique_id: string) {
  let url
  let v2 = false;

  switch ( group ) {
    case 'task':
      url = '/tasks/' + unique_id + '/view'
      break

    case 'news':
      url = '/news/' + unique_id
      break

    case 'document':
      url = '/documents/file/' + unique_id
      v2 = true;
      break

    case 'marketing':
      url = '/marketing/file/' + unique_id
      break

    case 'topic':
      url = '/forum/topic/' + unique_id
      break

    case 'shop_product':
      url = '/shop/accessories/' + unique_id
      break

    default:
      url = '#'
  }

  return {v2, url}
}