// slice reducer
import { createSlice } from '@reduxjs/toolkit'

export const emailComposeSlice = createSlice({
  name: 'emailCompose',
  initialState: {
    loading: true,
    selectedToContacts: [],
    selectedCcContacts: [],
    selectedBccContacts: [],
    replyToMessageId: null,
    initialHtml: '<p></p>',
    composing: false,
    content: '',
    subject: '',
    draft: null,
    expanded: false,
  },
  reducers: {
    setComposing: (state, action) => {
      state.composing = action.payload
    },
    setInitialHtml: (state, action) => {
      state.initialHtml = action.payload
    },
    setContent: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.content = action.payload
    },
    setDraft: (state, action) => {
      state.draft = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSelectedContacts: (state, action) => {
      if (typeof action.payload !== 'object' && Object.keys(action.payload).length > 0) {
        throw new Error('action.payload is not an object with key/value pairs or is empty.');
      }

      const key = action.payload.key;
      const value = action.payload.value;
      switch (key) {
        case 'to':
          state.selectedToContacts = value
          break;
        case 'cc':
          state.selectedCcContacts = value
          break;
        case 'bcc':
          state.selectedBccContacts = value
        default:
          break;
      }
      
    },
    setSubject: (state, action) => {
        state.subject = action.payload
    },
    setReplyToMessageId: (state, action) => {
      state.replyToMessageId = action.payload
    },
    setExpandModal: (state, action) => {
      state.expanded = action.payload
    },
    resetCompose: (state) => {
      state.selectedToContacts = []
      state.selectedCcContacts = []
      state.selectedBccContacts = []
      state.content = ''
      state.subject = ''
      state.replyToMessageId = null
      state.initialHtml = '<p></p>'
      state.composing = false
      state.draft = null
      state.loading = true
      state.expanded = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { setContent, setExpandModal, setLoading, setSelectedContacts, setDraft, setReplyToMessageId, setSubject, resetCompose, setInitialHtml, setComposing } = emailComposeSlice.actions

export default emailComposeSlice.reducer