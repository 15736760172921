import MainLayout from "../../layouts/MainLayout"
import { Button } from "../../components/Button"
import { Link } from "react-router-dom"
import { CheckIcon, HeartIcon } from "lucide-react"

export const ForumQuestion = () => {
    return (
        <MainLayout>
            <div className="ForumQuestion max-w-screen-lg w-full mx-auto mt-6 bg-white p-6 flex-grow space-y-6">
                <section>
                    <article className="space-y-4">
                       <header className="">
                            <h1 className="sm:text-2xl text-xl font-medium">Best way to onboard a child with asthma?</h1>
                       </header>
                       <div className="flex gap-x-4">
                        <div className="w-10 h-10 rounded-full bg-blue-500" />
                            <div className="text-sm leading-0">
                                <div className="font-medium">Daveyon Mayne</div>
                                <small>
                                    <time>4 days ago</time>
                                </small>
                            </div>
                       </div>
                       <p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Amet risus nullam eget felis eget nunc. Ornare aenean euismod elementum nisi. Tortor consequat id porta nibh venenatis cras sed. 
                        Eu tincidunt tortor aliquam nulla. Dolor sit amet consectetur adipiscing. Cursus euismod quis viverra nibh cras. Est lorem ipsum dolor 
                        sit amet consectetur adipiscing elit. Eu facilisis sed odio morbi quis commodo odio. Sit amet aliquam id diam maecenas ultricies</p>

                        <div>
                            <div className="flex items-center gap-2">
                                <button className="w-8 h-8 rounded-full flex items-center justify-center bg-blue-600 p-2 text-white">
                                    <HeartIcon className="w-5 h-5" />
                                </button>
                                <small className="text-xs font-medium">4 likes</small>
                            </div>

                            <div></div>
                        </div>
                    </article>
                </section>

                <section>
                    <header className="border-b py-3">
                        <span className="text-sm font-medium">12 Answers</span>
                    </header>

                    {/** A list of answers */}
                    <div className="space-y-3 divide-y">
                        <article className="ForumAnswer py-4">
                            <header className="mb-4">
                                <div className="flex gap-4 text-sm">
                                    <div className="w-10 h-10 rounded-full bg-green-500 ring ring-offset-4 ring-2 ring-green-500 relative">
                                        <CheckIcon className="absolute border border-2 border-white -top-2 -right-3 w-5 h-5 font-medium p-[1.5px] bg-green-500 rounded-full flex items-center justify-center text-white" />
                                    </div>
                                    <div className="leading-0 flex items-start gap-x-4">
                                        <div className="leading-0">
                                            <div className="font-medium">James Brown</div>
                                            <small className="block"><time>4 days ago</time></small>
                                        </div>
                                        
                                        <span className="text-xs font-medium text-green-500 rounded-full px-2 py-1.5 bg-gray-50">Best answer</span>
                                    </div>
                                 
                                </div>
                            </header>
                            <p className="text-sm">We're not sure, Daveyon. We may need to get trained on situations like this.</p>
                        </article>

                        <article className="ForumAnswer py-4">
                            <header className="mb-4">
                                <div className="flex gap-4 text-sm">
                                    <div className="w-10 h-10 rounded-full bg-green-500" />
                                    <div className="leading-0 flex items-start gap-x-4">
                                        <div className="leading-0">
                                            <div className="font-medium">Sarah Cox</div>
                                            <small className="block"><time>2 days ago</time></small>
                                        </div>
                                        
                                        <span className="hidden text-xs font-medium text-green-500 rounded-full px-2 py-1.5 bg-gray-50">Best answer</span>
                                    </div>
                                 
                                </div>
                            </header>
                            <p className="text-sm">We're not sure, Daveyon. We may need to get trained on situations like this.</p>
                        </article>
                    </div>


                </section>
            </div>
        </MainLayout>
    )
}