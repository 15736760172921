// slice reducer
import { createSlice } from '@reduxjs/toolkit'

export const selectedAvatar = createSlice({
  name: 'selectedAvatar',
  initialState: {
    ids: [], // Holds array of {id, unread}
  },
  reducers: {
    setSelectedAvatarId: (state, action) => {
      state.ids = action.payload
    },
    setSelectedAvatarUnreads: (state, action) => {
      state.unreads = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSelectedAvatarId, setSelectedAvatarUnreads } = selectedAvatar.actions

export default selectedAvatar.reducer