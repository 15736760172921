import { useQuery }                 from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useParams }                from 'react-router-dom'
import FileDropzone                 from '../../../components/FileDropzone/FileDropzone'
import { LoadingEllipsis }          from '../../../components/Loaders/Loaders'
import { setDocumentsData }         from '../../../features/documents/documentsSlice'
import { GET_DOCUMENTS }            from '../../../graphql/document'
import NavButton                    from '../NavButton'
import FolderTable                  from './FolderTable'

export default function ViewFolder() {
  const { page, unique_id } = useParams()
  const dispatch            = useDispatch()
  const folders             = useSelector( ( state: any ) => state.documents.folders )
  const folder              = unique_id ? folders.find( ( item: any ) => item.unique_id == unique_id ) : null

  const { loading, error, refetch } = useQuery( GET_DOCUMENTS, {
    variables:   {
      input: {
        page:      parseInt( page ) ?? 1,
        perPage:   15,
        sort:      'desc',
        sortBy:    'created_at',
        parent_id: unique_id ?? null
      }
    },
    onCompleted: ( { documents } ) => dispatch( setDocumentsData( documents ) )
  } )

  return (
    <div>
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

      { !loading &&
        <>
          <NavButton document={ folder } />

          { folder?.about && <div className="my-4 text-sm" dangerouslySetInnerHTML={ { __html: folder.about } } /> }

          <div className="mb-3">
            <FileDropzone
              newDocument
              documentUniqueId={ unique_id }
              refetch={ () => refetch() }
              helpText="You can select multiple files at once"
            />
          </div>
          <FolderTable folderId={ unique_id } />

          {
            error &&
            <div className="flex flex-col">
              <b>Error occurred when fetching documents:</b> { ' ' }
              { error.graphQLErrors.map( ( { message }, i: number ) => (
                <span key={ i }>{ message }</span>
              ) ) }
            </div>
          }
        </>
      }
    </div>
  )
}
