import { MagnifyingGlassIcon }    from '@heroicons/react/24/outline'
import { XMarkIcon }              from '@heroicons/react/24/solid'
import React, { useState }        from 'react'
// @ts-ignore
import FileViewer                 from 'react-file-viewer'
import ReactModal                 from 'react-modal'
import { useDispatch }            from 'react-redux'
import { setToasts, showToaster } from '../../features/toaster/toasterSlice'

ReactModal.setAppElement( '#root' )

export default function PreviewDocumentModal( props: any ): JSX.Element {
  const { file }                        = props
  const dispatch                        = useDispatch()
  const [ openPreview, setOpenPreview ] = useState( false )
  const [ filePath, setFilePath ]       = useState( '' )

  const handleOpenPreview = ( e: any ) => {
    e.preventDefault()

    setOpenPreview( true )
    setFilePath( `${ process.env.REACT_APP_UPLOAD_HOST }/download/${ file.path.split( '/' ).join( '~' ) }` )
  }

  const handleClosePreview = () => {
    setOpenPreview( false )
    setFilePath( '' )
  }

  const handleError = ( e: any ) => {
    // console.log( 'error in file-viewer: ', e )

    dispatch( setToasts( [ {
      id:      '1',
      type:    'error',
      message: 'File Preview Unsuccessful'
    } ] ) )

    dispatch( showToaster( true ) )
  }

  const isSupported = ( type: string ): boolean => {
    return [ 'pdf', 'csv', 'xslx', 'xlsx', 'docx', 'png', 'jpg', 'jpeg', 'gif', 'bmp', 'mp4', 'webm', 'mp3' ].includes( type )
  }

  const customStyles = {
    overlay: {
      zIndex: 9999
    },

    content: {
      padding: 0
    }
  }

  return (
    <div>
      <button
        className="p-0 mr-3 float-right hover:text-red-600"
        onClick={ handleOpenPreview }
      >
        <MagnifyingGlassIcon className="w-5 h-5" />
      </button>

      { file &&
        <ReactModal
          key={ `file-${ file.id }-1` }
          style={ customStyles }
          isOpen={ openPreview }
          contentLabel="Preview Document"
        >
          <button onClick={ handleClosePreview } className="close" color="default">
            <XMarkIcon className="w-5 h-5" />
          </button>
          {
            ( isSupported( file.extension ) === true && filePath !== '' )
            ? <FileViewer fileType={ file.extension } filePath={ filePath } onError={ handleError } key={ `file-${ file.id }-2` } />
            : <h2 className="p-4">Unsupported File Type</h2>
          }
        </ReactModal>
      }
    </div>
  )
}
