import { useLazyQuery }                         from '@apollo/client'
import { ArrowUturnLeftIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import { useEffect }                            from 'react'
import { useDispatch, useSelector }             from 'react-redux'
import { Link, useParams }                      from 'react-router-dom'
import FileDropzone                             from '../../../components/FileDropzone/FileDropzone'
import { LoadingEllipsis }                      from '../../../components/Loaders/Loaders'
import { setDocumentsData }                     from '../../../features/documents/documentsSlice'
import { GET_DOCUMENT_BY_ID, GET_DOCUMENTS }    from '../../../graphql/document'
import FileTable                                from './FileTable'

export default function ViewFile() {
  const { page, unique_id } = useParams()
  const dispatch            = useDispatch()
  const currentUser         = useSelector( ( state: any ) => state.currentUser.user )
  const folders             = useSelector( ( state: any ) => state.documents.folders )
  const document            = useSelector( ( state: any ) => state.documents.data ).find( ( item: any ) => item.unique_id == unique_id ) ?? {}
  const folder              = document?.parent_id ? folders.find( ( item: any ) => item.unique_id == document.parent_id ) : []

  const [ fetchCurrentDocument, {
    loading: loadingCurrentDocument, error: fetchCurrentDocumentError, refetch
  } ] = useLazyQuery( GET_DOCUMENT_BY_ID, {
    onCompleted: ( { documentByUid } ) => fetchDocuments(
      {
        variables: {
          input: {
            page:      page ? parseInt( page ) : 1,
            parent_id: documentByUid.parent_id
          }
        }
      } )
  } )

  const [ fetchDocuments, {
    loading: loadingDocuments, error: fetchDocumentsError
  } ] = useLazyQuery( GET_DOCUMENTS, { onCompleted: ( { documents } ) => dispatch( setDocumentsData( documents ) ) } )

  useEffect( () => {
    let shouldFetch = true
    if ( shouldFetch && !document.unique_id ) {
      fetchCurrentDocument( { variables: { input: { unique_id } } } )
    }

    return () => {
      shouldFetch = false
    }
  }, [ document.unique_id ] )

  return (
    <div>
      { ( loadingDocuments || loadingCurrentDocument ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

      {
        ( !loadingDocuments && !loadingCurrentDocument ) &&
        <>
          <h4>
            <div className="pt-2 text-xl font-medium mb-4 leading-tight">Versions of "{ document.title }"</div>
          </h4>

          <Link to={ folder.unique_id ? `/documents/folder/${ page ? page + '/' : '' }${ folder.unique_id }` : `/documents` } className="hidden">
            <button type="button" data-parent-id={ document.parent_id ?? 'null' } className="mb-4 flex items-center text-blue-secondary hover:text-blue-secondary-darker">
              <ArrowUturnLeftIcon className="w-4 h-4" />
              <span className="pl-1 text-left">{ folder.unique_id ? folder.title : 'Home' }</span>
            </button>
          </Link>

          {
            currentUser.admin &&
            <Link to={ `/documents/file/edit/${ page ? page + '/' : '' }${ document.unique_id }/edit` }>
              <button type="button" className="mb-4 flex items-center text-blue-secondary hover:text-blue-secondary-darker">
                <PencilSquareIcon className="w-4 h-4" />
                <span className="pl-1 text-left">Edit</span>
              </button>
            </Link>
          }

          <p className="text-muted text-sm mb-4">Documents keep older versions and are displayed in the order they were uploaded.</p>

          {/*{ currentUser.admin && <FileUploadForm /> }*/ }
          <div className="mb-3">
            <FileDropzone
              refetch={ () => refetch() }
              documentUniqueId={ document.unique_id }
              helpText="You can select multiple files at once"
            />
          </div>

          <FileTable
            refetch={ () => refetch() }
            document={ document }
            canDelete={ currentUser.admin }
          />

          {
            fetchCurrentDocumentError &&
            <div className="flex flex-col">
              <b>Error occurred when fetching current document:</b> { ' ' }
              { fetchCurrentDocumentError.graphQLErrors.map( ( { message }, i: number ) => (
                <span key={ i }>{ message }</span>
              ) ) }
            </div>
          }

          {
            fetchDocumentsError &&
            <div className="flex flex-col">
              <b>Error occurred when fetching current folder documents:</b> { ' ' }
              { fetchDocumentsError.graphQLErrors.map( ( { message }, i: number ) => (
                <span key={ i }>{ message }</span>
              ) ) }
            </div>
          }
        </>
      }
    </div>
  )
}
