// @ts-nocheck
import { useQuery }           from '@apollo/client'
import { useState }           from 'react'
import { useParams }          from 'react-router-dom'
import { LoadingEllipsis }    from '../../../components/Loaders/Loaders'
import { GET_DOCUMENT_BY_ID } from '../../../graphql/document'
import FileForm               from './FileForm'

export default function EditFile() {
  const { page, unique_id }             = useParams()
  const [ document, setDocument ] = useState( null )
  const { loading, error }        = useQuery( GET_DOCUMENT_BY_ID, {
    variables:   { input: { unique_id } },
    onCompleted: ( { documentByUid } ) => setDocument( documentByUid )
  } )

  return (
    <div>
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
      { !loading && document &&
        <>
        <h4 className="font-medium text-xl">
          Edit File
        </h4>

        <FileForm document={ document } parentId={ unique_id } page={ page ?? 1 }></FileForm>
        </>
      }
      {
        error &&
        <div className="flex flex-col">
          <b>Error occurred when fetching documents:</b> { ' ' }
          { error.graphQLErrors.map( ( { message }, i: number ) => (
            <span key={ i }>{ message }</span>
          ) ) }
        </div>
      }
    </div>
  )
}
