import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/Button';
import FolderBox from '../FolderBox/FolderBox';
import { EyeIcon, FunnelIcon } from '@heroicons/react/20/solid';
import Api from '../../utilities/axios';
import { DOCUMENT_LOCATIONS, CREATE_DOCUMENT_LOCATION } from '../../graphql/document';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

interface DocumentLocationSelectionProps {
    // The actual ID for the document and NOT the unique_id
    document: any;
}

export default function DocumentLocationSelection(props: DocumentLocationSelectionProps) {
    const { document } = props;
    const currentUser = useSelector((state: any) => state.currentUser.user);
    const [open, setOpen] = useState<boolean>(false)
    const [query, setQuery] = useState<string>('')
    const [locations, setLocations] = useState<any[]>([])
    const [defaultLocations, setDefaultLocations] = useState<any[]>([])
    const [getDocumentLocations, { loading, error, data }] = useLazyQuery(DOCUMENT_LOCATIONS, {
       fetchPolicy: "no-cache"
    });
    const [selectedLocationUniqueIds, setSelectedLocationUniqueIds] = useState<string[]>([])
    const [savedLocations, setSavedLocations] = useState<string[]>([])
    const [createDocumentLocation, { loading: isCreating }] = useMutation(CREATE_DOCUMENT_LOCATION, {
        onCompleted: (data) => onClose(),
        onError: (error) => console.log(error)
    });

    const ref: any = useRef()

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_V1_HOST;

        if (open) {
            Api(baseUrl).get('/api/v2-locations')
            .then((res: any) => {
                const locs = res.data.all
                setLocations(locs)

                setDefaultLocations(locs)

                ref.current.focus()
            })
            .catch(e => console.log(e));
        }

    }, [open])

    /**
     * Always fetch as cache is used by default.
     * 
     * We need this data to show when a document is being restricted with the eye icon made solid. See pageMarkup.
     * 
     */
    useEffect(() => {
        getDocumentLocations({
            variables: { input: {} }
        })
    }, [])

    /**
     * Set the initial selected locations.
     * 
     */
    useEffect(() => {
        if (!loading && data && document) {
            const ids: string[] = data.documentLocations.filter((d: any) => d.document_id === document.id).map((d: any) => d.location_unique_id)
            setSelectedLocationUniqueIds(ids)
            setSavedLocations(ids)
        }
    }, [loading, data, document, open])

    const onClose = () => {
        setQuery('');
        setOpen(false)
        getDocumentLocations({
            variables: { input: {} }
        })
    }
    const onQuery = (query: string) => {
        setQuery(query)

         const filtered = locations.filter((loc: any) => 
            loc.name.toLowerCase().includes(query.toLowerCase())
        );

        if (query.length > 0) setLocations(filtered)
    }

    const onShouldReset = (e: any) => {
        if (e.target.value.length === 0) {
            setLocations(defaultLocations);
        }
    }

     // Handle checkbox change to add or remove location's unique ID
     const handleCheckboxChange = (uniqueId: string) => {
        setSelectedLocationUniqueIds(prevIds => {
            if (prevIds.includes(uniqueId)) {
                // If the ID is already in the array, remove it
                return prevIds.filter(id => id !== uniqueId);
            } else {
                // If the ID is not in the array, add it
                return [...prevIds, uniqueId];
            }
        });
    }

    const onSave = (event: any) => {
        event.preventDefault()

        createDocumentLocation({
            variables: { input: {document_id: document.id, location_unique_ids: selectedLocationUniqueIds, country: null} }
        })
    }

    const location = (uniqueId: string) => defaultLocations.find((l: any) => l.unique_id === uniqueId)

    const toggleCheckAll = () => {
        if (locations.length === selectedLocationUniqueIds.length) {
            // clear selection
            setSelectedLocationUniqueIds([]);
        } else {
            const allUniqueIds = locations.map((location: any) => location.unique_id);
            setSelectedLocationUniqueIds(allUniqueIds);
        }
    }

    const markup = (
        <aside className="DocumentLocationSelection flex sm:gap-x-4">
            <div className='sm:block hidden'>
                <FolderBox document={ document } hidePin hideColourSelection />
                
                <div className='relative mt-3 h-[17rem] overflow-y-auto space-y-2'>
                    {savedLocations.map((uniqueId: string) => (
                        <button key={ uniqueId } type='button' className='text-left text-xs block font-medium truncate w-fit max-w-[9rem] px-2 py-1 rounded-full bg-gray-100'>
                            {location(uniqueId)?.name}
                        </button>
                    ))}
                </div>
            </div>
           <div className='flex-1'>
                <header className="border-b pb-1 mb-2">
                    <h5 className="font-medium">Choose Locations</h5>
                    <p className="text-sm text-gray-500 max-w-md">Choose the location(s), that should only see this file/folder.
                    By default, all locations have access.
                    </p>
                </header>
                <div className="max-w-screen-sm w-full mx-auto">
                    <div className='relative flex items-center overflow-hidden rounded-full p-1'>
                        <FunnelIcon className='absolute h-4 w-4 z-10 left-3' />
                        <input
                            ref={ ref }
                            value={ query }
                            type="search"
                            onInput={onShouldReset}
                            autoCorrect="off"
                            placeholder='Type location to filter...'
                            className='w-full text-sm font-medium rounded-full pl-7'
                            onChange={(e: any) => onQuery(e.target.value)}
                        />
                    </div>
                
                    <div className='flex justify-end px-3'>
                        
                        <button 
                            className={classNames('text-sm', {
                                'invisible': locations.length ===0
                            })} 
                            onClick={toggleCheckAll} 
                            type="button">Check all</button>
                    
                    </div>
            
                    

                    <form className="px-3" onSubmit={onSave}>
                        <fieldset>
                            <legend className="hidden">Locations</legend>
                            <div className="flex flex-col text-sm divide-y h-72 overflow-y-auto">
                                {locations.map((location: any) => (
                                    <label key={ location.unique_id } className="flex items-center py-2 cursor-pointer">
                                    <span className="flex-1 flex items-center gap-x-1">
                                        <span >{ location.name }</span>
                                    </span>
                                    <input
                                            type="checkbox"
                                            name="location"
                                            value={location.unique_id}
                                            checked={selectedLocationUniqueIds.includes(location.unique_id)}
                                            onChange={() => handleCheckboxChange(location.unique_id)}
                                        />
                                    </label>
                                ))}
                                {locations.length === 0 && query && (
                                    <p className='pt-3 text-center'>No results found for <b>{ query }</b></p>
                                )}
                            </div>
                        </fieldset>

                        <footer className='border-t flex items-center justify-end pt-3'>
                        <Button submittable label='Save' loading={ isCreating } />
                        </footer>
                    
                    </form>
                </div>
           </div>
        </aside>
    );

    const pageMarkup = currentUser?.admin ? (
        <div>
            <button
                onClick={() => setOpen(true)} 
                className={'flex items-center group'}>
                <EyeIcon className={classNames('w-4 h-4 opacity-[0.33] group-hover:opacity-[1]', {
                    '!opacity-[1]': open || selectedLocationUniqueIds.length
                })} />
            </button>
            <Modal
                fit
                isOpen={open}
                onClose={() => onClose()}
                content={ markup }
            />
        </div>
    ) : null

    return pageMarkup;
}