// @ts-nocheck
import { useLazyQuery }              from '@apollo/client'
import { useLayoutEffect, useState } from 'react'
import { useParams }                 from 'react-router-dom'
import { LoadingEllipsis }           from '../../../components/Loaders/Loaders'
import { GET_DOCUMENT_BY_ID }        from '../../../graphql/document'
import FileForm                      from './FileForm'

export default function NewFile() {
  const { unique_id }                         = useParams()
  const [ parentDocument, setParentDocument ] = useState( null )

  const [ fetchParentDocument, { loading } ] = useLazyQuery( GET_DOCUMENT_BY_ID, {
    onCompleted: ( { documentByUid } ) => setParentDocument( documentByUid )
  } )

  useLayoutEffect( () => {
    if ( unique_id ) {
      fetchParentDocument( { variables: { input: { unique_id } } } )
    }
  }, [] )

  return (
    <div>
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
      { !loading &&
        <>
        <h4 className="font-medium text-xl">
          New File
          { parentDocument && <span> in <em>{ parentDocument.title }</em></span> }
        </h4>

        <FileForm parentId={ unique_id }></FileForm>
        </>
      }
    </div>
  )
}
