import FolderTableItem from './DocumentFolders/FolderTableItem'
import { useSelector } from 'react-redux'

export default function RecentDocuments( props: any ) {
  const lastWeekDate = ( new Date().getTime() - 7 * 24 * 60 * 60 * 1000 )
  const documents    = useSelector( ( state: any ) => state.documents?.data ?? [] ).filter( ( item: any ) => ( new Date( item.updated_at ).getTime() >= lastWeekDate ) )
                                                                            .sort( ( item1: any, item2: any ) => item1.updated_at < item2.updated_at ? 1 : -1 )
  // const documents = useSelector( state => state.documents.folders)
  return (
    <div className="w-full overflow-x-auto">
      { documents.length < 1 && <p>There are no files published recently.</p> }
      { documents.length > 0 &&
        <table className="documents-table w-full text-sm">
          <thead>
            <tr>
              <th className="w-[1%] border-b-2 border-gray-table-border p-3 px-0"></th>
              <th className="w-[90%] border-b-2 border-gray-table-border p-3 align-bottom text-left">Name</th>
              <th className="w-[9%] border-b-2 border-gray-table-border p-3"></th>
            </tr>
          </thead>
          <tbody>
            { documents.map( ( item: any, idx: any ) =>
                               <FolderTableItem key={ item.id } itemDetails={ item } idx={ idx } /> ) }
          </tbody>
        </table>
      }
    </div>
  )
}
