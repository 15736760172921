import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { store } from './store';

const isLoginPage = window.location.pathname.includes('login');
const isPasswordPage = window.location.pathname.includes('password');
const head = document.getElementsByTagName('head')[0];
let script = document.createElement('script');
script.setAttribute('src', process.env.REACT_APP_BUNDLE_PATH);

if (!isLoginPage && !isPasswordPage) {
  console.log("appending header", process.env.REACT_APP_BUNDLE_PATH)
  head.appendChild(script);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Provider>
  </ApolloProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();