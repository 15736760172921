import { gql } from '@apollo/client'

const DownloadLogFragment = gql`
    fragment DownloadLogData on DownloadLog {
        id
        document_id
        document_unique_id
        document_oid
        file_id
        file_unique_id
        file_oid
        user
        user_id
        user_oid
        name
        type
        created_at
        updated_at
   }
`

const LinksFragment = gql`
    fragment LinksData on PaginatorLinks {
        totalResults
        totalPages
        page
        perPage
        prevPage
        nextPage
    }
`

export const GET_DOWNLOAD_LOGS_TO_CSV = gql`
  query DownloadLogs($input: QueryDownloadLogsToCSVInput!) {
      downloadLogsToCSV(input: $input) {
          ...DownloadLogData
          }
      }
      ${ DownloadLogFragment }
`

export const GET_DOWNLOAD_LOGS = gql`
  query DownloadLogs($input: QueryDownloadLogsInput!) {
      downloadLogs(input: $input) {
          data {
            ...DownloadLogData
          }
          links {
            ...LinksData
          }
      }
    }
    ${ DownloadLogFragment }
    ${ LinksFragment }
`

export const CREATE_DOWNLOAD_LOG = gql`
   mutation CreateDownloadLog($input: MutationCreateDownloadLogInput!) {
     createDownloadLog(input: $input) {
       __typename
       ... on FieldError {
         field
         message
       }
       ... on MutationCreateDownloadLogSuccess {
         data
       }
     }
   }
`
