import { ForumTable } from "../ForumTable/ForumTable"
import { GET_FORUM_QUESTIONS } from "../../graphql/forum/question"
import { useQuery } from "@apollo/client"

export const ForumQuestions = () => {
    const { loading, error, data } = useQuery(GET_FORUM_QUESTIONS, {
        variables: { input: {perPage: 25, sort: "desc", sortBy: "created_at"} },
        onCompleted: (data) => console.log(data)
    });

    if (loading) return <p>Loading...</p>

    console.log("forum errors", error)

    if (error) return <p>ERROR</p>


    return (
        <div className="ForumQuestions">

            <ForumTable questions={data?.questions?.data} />
        </div>
    )
}