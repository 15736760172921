import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useMutation } from '@apollo/client';
import AuthLayout from "../../layouts/AuthLayout";
import { LOGIN } from './graphql';
import {
    TextField,
    Button
} from "../../components";

export default function Login() {

    // We would not need to use cookies here.
    // Once set, it's available in the browser
    const [_, setCookie] = useCookies([]);

    // email state/function
    const [email, setEmail] = useState('');
    const handleEmailChange = useCallback((newValue) => setEmail(newValue), []);

    // password state/function
    const [password, setPassword] = useState('');
    const handlePasswordChange = useCallback((newValue) => setPassword(newValue), []);

    const [loginUser, { data, loading, error }] = useMutation(LOGIN, {
        variables: {
            input: {email, password}
        },
        onCompleted: ({ login }) => saveTokenAndRedirect(login?.data?.token),
        onError: (error) => console.log('ERROR', error)
    });

    // const errorFor = (field, type) => data?.login2FA?.__typename === 'FieldError' && 
    //                                     data?.login2FA?.field === field ? 
    //                                     data?.login2FA[type] : ''

    const errorFor = (field, type) => data?.login?.__typename === 'FieldError' && 
                                        data?.login?.field === field ? 
                                        data?.login[type] : data?.login?.message

    const onSubmit = (event) => {
        event.preventDefault();
        loginUser();
    }

    const saveTokenAndRedirect = (token) => {
        if (token) {
            setCookie('token', token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const nextParam = url.searchParams.get('next');
            if (nextParam) {
                window.location.href = nextParam
            } else {
                window.location.href = '/'
            }
        }
    }

    const pageMarkup = () => (
        <AuthLayout heading="Login to your account">
           <form 
            className="space-y-6 relative" 
            onSubmit={onSubmit}>

                <TextField
                    isRequired
                    label="Email address"
                    value={email}
                    errorField={errorFor('email', 'field')}
                    errorMessage={errorFor('email', 'message')}
                    name="email" 
                    type="email"
                    onChange={handleEmailChange}
                />

                <TextField
                    isRequired
                    label="Password"
                    value={password}
                    errorField={errorFor('password', 'field')}
                    errorMessage={errorFor('password', 'message')}
                    name="password" 
                    type="password"
                    onChange={handlePasswordChange}
                />

                
                <div>
                    <p className="mb-2 text-sm text-blue-sky">
                        <Link to="/password/reset">Forgot Password</Link>
                    </p>
                    <Button loading={ loading } submittable label="Login" onAction={() => {}} />
                </div>
            </form>
        </AuthLayout>
    );


    return pageMarkup();
}