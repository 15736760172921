import { LoadingEllipsis }          from '../Loaders/Loaders'
import SearchResultItem             from './SearchResultItem';

interface SearchResultsProps {
    results: any
    query: string
    loading: boolean;
    onClose?: () => void
}

export default function SearchResults(props: SearchResultsProps) {
    const STORAGE_KEY = 'bhm_recent_search'
    const { results, query, loading, onClose } = props;
    

    const addQueryToLocalStorage = (event: any) => {
        onClose()
        // We need the path, not the full url
        const {path, title, folder, extension, filePath} = event.currentTarget.dataset
        const queryLowerCase = query.toLowerCase();
        const newObj = { 
            query: queryLowerCase, 
            path, 
            title,
            folder,
            extension,
            filePath
        };
        
    
        // Get the existing array from local storage or initialize it as an empty array
        let existingArray: any = [];
        const existingArrayStr = localStorage.getItem(STORAGE_KEY);
    
        if (existingArrayStr) {
            try {
                // Parse the existing JSON array from local storage
                existingArray = JSON.parse(existingArrayStr);
    
                // Check if there's an object with the same query
                const existingIndex = existingArray.findIndex((item: any) => item.query === queryLowerCase);
    
                if (existingIndex !== -1) {
                    // If a matching query is found, update the existing object
                    existingArray[existingIndex] = newObj;
                } else {
                    // If no matching query is found, add the new object to the array
                    existingArray.push(newObj);
                }

                // Limit the array to the last 15 objects
                if (existingArray.length > 15) {
                    existingArray = existingArray.slice(-15);
                }
            } catch (error) {
                console.error('Error parsing existing array:', error);
            }
        } else {
            // If no existing array, create a new one with the new object
            existingArray = [newObj];
        }
    
        // Store the updated or new array back in local storage
        localStorage.setItem(STORAGE_KEY, JSON.stringify(existingArray));
    };


    return (
        // <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" />
        <div className='relative flex flex-col flex-grow'>
            {loading && (
                <div className='absolute bg-black/5 z-10 flex items-center justify-center top-0 bottom-0 left-0 right-0'>
                    <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" />
                </div>
            )}
                {!query.length && (
                    <div className='font-medium mt-3 pb-3 px-4 border-b'>
                        Recent
                    </div>
                )}
                
                { results && results.length ? (
                    <ul role='listbox'  className='divide-y flex-grow max-h-[90vh] overflow-y-auto h-full'>
                    {(results || []).map((document: any) => (
                        
                        <li key={document.id} role="option" className='hover:bg-gray-50 px-4 py-2 flex items-center relative'>
                            <SearchResultItem
                                addQueryToLocalStorage={addQueryToLocalStorage}
                                document={ document } 
                            />
                        </li>
                    ))}
                    
                </ul>
                ) : null}

                {query.length && !loading && results && results.length === 0 ? (
                    <p className='mt-3 text-center text-sm'>No results for "{ <span className='font-medium'>{ query }</span> }"</p>
                ) : null}
           </div>
    )
}
