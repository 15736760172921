import classNames              from 'classnames'
import { usePagination, DOTS } from './usePagination'
import './pagination.scss'

const Pagination = props => {
  const {
          onPageChange,
          totalCount,
          siblingCount = 1,
          currentPage,
          pageSize,
          className
        } = props

  const paginationRange = usePagination( {
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  } )

  if ( currentPage === 0 || paginationRange.length < 2 ) {
    return null
  }

  const onNext = () => {
    onPageChange( currentPage + 1 )
  }

  const onPrevious = () => {
    onPageChange( currentPage - 1 )
  }

  let lastPage = paginationRange[ paginationRange.length - 1 ]
  return (
    <ul
      className={ classNames( 'pagination-container', { [ className ]: className } ) }
    >
      <li
        className={ classNames( 'pagination-item', {
          disabled: currentPage === 1
        } ) }
        onClick={ onPrevious }
      >
        <div className="arrow left" />
      </li>
      { paginationRange.map( ( pageNumber, index ) => {
        if ( pageNumber === DOTS ) {
          return <li className="pagination-item dots" key={ index }>&#8230;</li>
        }

        return (
          <li
            className={ classNames( 'pagination-item', {
              selected: pageNumber === currentPage
            } ) }
            key={ index }
            onClick={ () => onPageChange( pageNumber ) }
          >
            { pageNumber }
          </li>
        )
      } ) }
      <li
        className={ classNames( 'pagination-item', {
          disabled: currentPage === lastPage
        } ) }
        onClick={ onNext }
      >
        <div className="arrow right" />
      </li>
    </ul>
  )
}

export default Pagination
