import { DocumentIcon, FolderIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon }    from '@heroicons/react/24/solid'
import { useState }                 from 'react'
import { useSelector }              from 'react-redux'
import { Link, useParams }          from 'react-router-dom'
import TextBadge                    from '../../../components/Badge/TextBadge'
import DocumentLocationSelection    from '../../../components/DocumentLocationSelection/DocumentLocationSelection'
import PinMe                        from '../../../components/PinMe/PinMe'

export default function FolderTableItem( props: any ) {
  const { page }                                           = useParams()
  const { itemDetails: item, idx, isNew, readIds, search } = props
  const currentUser                                        = useSelector( ( state: any ) => state.currentUser.user )

  const [ isOpen, setIsOpen ] = useState( false )

  const toggleCollapse = () => setIsOpen( !isOpen )

  return (
    <>
      <tr>
        <td className={ isOpen ? 'p-3 px-0' : 'border-b border-gray-table-border p-3 px-0' }>
          { item.folder && <FolderIcon className="w-5 h-5" /> }
          {/*if (item.files[0] !== undefined && _mimeTypes[item.files[0].mime_type] !== undefined) icon = _mimeTypes[item.files[0].mime_type]*/ }
          { !item.folder && <DocumentIcon className="w-5 h-5" /> }
        </td>
        <td className={ isOpen ? 'p-3' : 'border-b border-gray-table-border p-3' }>
          { item.folder &&
            <Link to={ `/documents/folder/${ item.unique_id }` }>{ item.title }</Link>
          }
          { !item.folder &&
            <Link to={ `/documents/file/${ page ? page + '/' : '' }${ item.unique_id }` }>
              {/*{ ( isNew === true ) && <TextBadge classes="mr-2" color="blue-secondary" text="white">New</TextBadge> }*/}
              { item.title }
            </Link>
          }
          { currentUser.admin && !item.folder && item.draft && !search &&
            <TextBadge classes="ml-2" color="yellow-primary">Draft</TextBadge> }
          {
            currentUser.admin && !item.folder && !item.draft && item.publish && !search &&
            <TextBadge classes="ml-2" color="yellow-primary">Published</TextBadge>
          }
          {
            currentUser.admin && !item.folder && !item.draft && !item.publish && !search &&
            <TextBadge classes="ml-2" color="blue-secondary" text="white">Upcoming</TextBadge>
          }
        </td>
        <td className={ isOpen ? 'p-3' : 'border-b border-gray-table-border p-3' }>
          <div className="flex items-center gap-2 justify-end">
            {
              item.about && item.about.trim() !== '' &&
              <button className="p-0">
                <InformationCircleIcon className="w-4 h-4" id={ `itemInfo${ idx }` } onClick={ toggleCollapse } />
              </button>
            }
            <DocumentLocationSelection document={ item } />
            <PinMe document={ item } />
          </div>
        </td>
      </tr>
      <tr className={ isOpen ? 'border-b border-gray-table-border p-3' : 'hidden' }>
        <td className="px-3 pb-3 pt-1" colSpan={ 4 }>
          <p dangerouslySetInnerHTML={ { __html: item.about } } />
          { item.about && item.about.trim() === '' && <p>There are no details.</p> }
        </td>
      </tr>
  </>
  )
}
