import { Image } from "../Image";
import { FormatDate } from "../../utilities";

export default function NewsArticle(props: any) {
    const { title, headline, featured_image, published_at, path } = props;
    return (
        <article className="NewsArticle bg-white rounded-xl md:max-w-[320px] min-h-[360px] w-full md:p-4 p-3">
            <a href={ process.env.REACT_APP_V1_HOST + path } className="flex flex-col h-full">
                <div className="w-full rounded-lg md:h-[200px] h-[150px] relative overflow-hidden mb-3">
                    <Image 
                        defaultSrc="https://www.bananamoon-harbury.co.uk/storage/articles/new-website-launched-large.jpg"
                        src={featured_image }
                        alt={ title }
                        klass={ 'w-full h-full object-cover'}
                    />
                </div>
                <header className="flex-grow leading-0">
                    <h5 className="font-medium sm:text-lg leading-snug mb-1">{ title} </h5>
                    <p className="text-sm">{ headline }</p>
                </header>
                <footer className="flex gap-3 md:flex-row flex-col items-center justify-between">
                    <time className="text-xs" dateTime={ published_at }>
                        Published {FormatDate(published_at)}
                    </time>
                    <span className="block text-center text-xs font-medium px-3 rounded-full py-1.5 bg-yellow-300">Read</span>
                </footer>
            </a>
        </article>
    )
}