import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import createAxiosInstance  from '../../utilities/axios';
import LatestProduct from "./LatestProduct";

export default function LatestProducts() {
    const currentUser = useSelector((state: any) => state.currentUser.user);
    const [items, setItems] =  useState([]);
    
    const getLatestProducts = async () => {
        const Api = createAxiosInstance(process.env.REACT_APP_V1_HOST);
    
        try {
            const { data } = await Api.get('/api/v2-latest-products');
            setItems(data);
        } catch (error) {
            console.log("Error getting stats", error)
        }
    }

    useEffect(() => {
        if (currentUser) {
            getLatestProducts()
        }
    }, [currentUser]);

    if (items.length === 0) return

    return (
        <section className="LatestProducts bg-white rounded-xl px-3 py-4">
            <header className="mb-3 flex items-center justify-between">
                <h5 className="text-lg font-semibold">Latest products</h5>
                <a href={  process.env.REACT_APP_V1_HOST + '/shop/products' } className="text-sm font-medium px-3 py-1 bg-yellow-300 rounded-full">View all</a>
            </header>

            <div className="space-y-3">
                {items.map((product: any) => <LatestProduct key={product.id} {...product} />)}  
            </div>
        </section>
    )
}