import FolderItem      from './FolderItem'
import { useSelector } from 'react-redux'

export default function FolderItemChildren( props: any ) {
  const folders  = useSelector( (state: any) => state.documents.folders )
  const children = folders.filter( (item: any) => item.parent_id === props.folderId && item.folder )

  return (
    <div className="folder-item-children">
      { children.map( (item: any) => <FolderItem key={ item.unique_id } folderId={ item.unique_id } /> ) }
    </div>
  )
}
