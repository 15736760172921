import { Link } from "react-router-dom"

interface ForumTableItem {
    question: {
        title: string
        body?: string
        id: string
        slug: string
        sticky: boolean
        created_at: string
        answers: any[]
        topic?: {
            id: string
            title: string
        }
    }
}


export const ForumTableItem = (props: ForumTableItem) => {
    const { question } = props
    return (
        <tr id={ question.id } className="ForumTableItem bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <Link to={`/forum/question/${question.id}`} className="text-blue-500">
                    <span className="mb-2">{ question.title }</span>
                </Link>
                <div className="gap-1 flex items-center">
                    <span className="w-3 h-3 bg-orange-600 rounded inline-block"></span>
                    {question.topic ? (
                        <small>
                            <Link to={'/'}>
                                <span className="text-xs">{ question.topic?.title }</span>
                            </Link>
                        </small>
                    ) : <small><i>Uncategorised</i></small>}
                </div>
                <small>Asked 10 months ago by Susie Bearne, Lower Sundon</small>
            </th>
            <td className="sm:px-6 py-4">
                {/* Some avatars: poster first then however answers limited to 3 etc */}
                 {/** Also reuse the component in the message */}
                 <div className="flex -space-x-4">
                    <img className="w-8 h-8 border-2 border-white rounded-full dark:border-gray-800" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="" />
                    <img className="w-8 h-8 border-2 border-white rounded-full dark:border-gray-800" src="https://flowbite.com/docs/images/people/profile-picture-2.jpg" alt="" />
                    <img className="w-8 h-8 border-2 border-white rounded-full dark:border-gray-800" src="https://flowbite.com/docs/images/people/profile-picture-3.jpg" alt="" />
                    <img className="w-8 h-8 border-2 border-white rounded-full dark:border-gray-800" src="https://flowbite.com/docs/images/people/profile-picture-4.jpg" alt="" />
                </div>
            </td>
            <td className="sm:px-6 py-4">
                20
            </td>
            <td className="sm:px-6 py-4">
                <div className="text-center">
                    <div><i>Kim Tallis</i></div>
                    <small>
                        <time>10 months ago</time>
                    </small>
                </div>
            </td>
        </tr>
    )
}