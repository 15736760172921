import { useLazyQuery }                         from '@apollo/client'
import { MagnifyingGlassIcon }                  from '@heroicons/react/20/solid'
import DocumentsTable                           from './DocumentsTable'
import { LoadingEllipsis }                      from '../../components/Loaders/Loaders'
import { setDocumentFolders, setDocumentsData } from '../../features/documents/documentsSlice'
import { GET_DOCUMENTS, GET_DOCUMENT_FOLDERS }  from '../../graphql/document'
import MainLayout                               from '../../layouts/MainLayout'
import { debounce }                             from 'lodash-es'
import { useLayoutEffect }                      from 'react'
import { useSelector, useDispatch }             from 'react-redux'
import PinnedDocuments                          from '../../components/PinnedDocuments/PinnedDocuments'
import SearchBar                                from '../../components/Search/SearchBar'

export default function Documents(): any {
  const folders             = useSelector( (state: any) => state.documents.folders )
  const dispatch            = useDispatch()
  const placeholder: string = 'Search...'
  const currentTenant: any  = useSelector( ( state: any ) => state.currentTenant.tenant )
  const bodyInfo: string    = currentTenant?.pages?.documents?.body ?? ''

  // start fetching section
  const [ fetchFolders, {
    loading: fetchingDocumentFolders,
    error:   documentFoldersError
  } ] = useLazyQuery( GET_DOCUMENT_FOLDERS, { onCompleted: ( { documentFolders } ) => dispatch( setDocumentFolders( documentFolders ) ) } )

  useLayoutEffect( () => {
    if ( !folders.length ) {
      fetchFolders()
    }
  }, [] )
  // end fetching section

  // start search section
  const [ searchDocuments, { data, loading } ] = useLazyQuery( GET_DOCUMENTS )

  async function getDocuments( page: number = 1, query: string = '' ): Promise<any>
  {
    const search = await searchDocuments( {
                                            variables: {
                                              input: {
                                                page,
                                                query,
                                                perPage: 15,
                                                sort:    'desc',
                                                sortBy:  'created_at'
                                              }
                                            }
                                          } )

    return search?.data?.documents?.data.length
           ? search.data.documents
           : []
  }

  const debouncedSearch: any = debounce( async ( searchTerms: any ): Promise<void> => await getDocuments( 1, searchTerms ), 1000 )
  const handleSearch         = ( value: string ): void => {
    if ( value.length && value.trim().length >= 3 ) {
      debouncedSearch( value )
    }
  }
  // end search section

  // @ts-ignore
  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <SearchBar
            resultsInModal
            loading={loading}
            results={ data?.documents?.data ?? null }
            onChange={ handleSearch }
            placeholder="Search documents"
          />

          <div className="row flex items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="col flex w-full">
              <div className="card w-full relative">
                <div className="card-body">
                  <div className="heading w-full mb-8">
                    <h4 className="card-title mb-0 text-xl text-medium">Documents</h4>
                    <p className="text-muted text-sm text-gray-500">{ bodyInfo }</p>
                  </div>

                  { /**  Pinned documents --> */ }
                  <PinnedDocuments />

                  <div className="doc-app">
                    {
                      fetchingDocumentFolders
                      ? <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" />
                      : <DocumentsTable />
                    }
                    {
                      documentFoldersError &&
                      <div className="flex flex-col">
                        <b>Error occurred when fetching document folders:</b> { ' ' }
                        { documentFoldersError.graphQLErrors.map( ( { message }, i: number ) => (
                          <span key={ i }>{ message }</span>
                        ) ) }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
