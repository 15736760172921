import React from "react";
import PropTypes from 'prop-types';
import logo from '../assets/logo.jpg';

/**
 * Authentication layout.
 * 
 * @param {*} props 
 * @returns HTML Node
 */
export default function AuthLayout(props) {
    return (
        <main className="mt-16 sm:px-0 px-3 relative z-[9999999]">
            <article className="sm:w-[375px] w-full p-6  m-auto bg-white shadow-xl relative">
                <header className="mb-6 text-center relative">
                    <div className="w-28 h-28 bg-white rounded-full absolute left-0 right-0 m-auto -top-16 shadow-xl overflow-hidden p-3">
                        <img src={logo} alt="" />
                    </div>
                    <h1 className="pt-16">{ props.heading }</h1>
                </header>

                { props.children }
            </article>
        </main>
    );
}

AuthLayout.propTypes = {
    heading: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};