import MainLayout from '../../layouts/MainLayout'

export default function BmPlatformHelpCentre(): JSX.Element {
  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="flex w-full mb-4">
              <header className="mb-4">
                <h1 className="sm:text-2xl text-xl font-bold">BM Platform</h1>
                <p className="text-sm text-gray-500">Help Centre</p>
              </header>
            </div>

            <div className="flex flex-col w-full text-sm leading-normal">
              <div className="mb-4">Welcome to our BRAND NEW Help Centre.</div>

              <div className="mb-4">
                <a
                  target="_blank"
                  rel="nofollow"
                  href="https://helpcentre.blossomeducational.com/en/"
                  className="text-blue-secondary"
                >
                  https://helpcentre.blossomeducational.com/en/
                </a>
              </div>

              <div className="mb-4">
                The Help Centre is filled with Step by Step Guides, Tutorial Videos and FAQs. There is a smart search bar
                at the top to quickly obtain answers to your questions. The Help Centre is split into 4 areas, these
                are
              </div>

              <div className="mb-4">
                <ul className="w-full list-disc pl-8 mb-4">
                  <li>Getting Started</li>
                  <li>Child Development</li>
                  <li>Operations</li>
                  <li>Finance</li>
                </ul>

                There is a separate area for the Parent App split into Nursery & Parent.
              </div>

              <div className="mb-4">
                The content is continuously being reviewed and updated based on new FAQ’s and releases. If you can not
                find the answer to a question you are looking for, please email Bianca at Blossom on <a
                className="text-blue-secondary" href="mailto:bianca@blossomeducational.com">bianca@blossomeducational.com</a>.
              </div>

              <div className="mb-4">We hope you enjoy the new Help Centre.</div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
