import { DocumentIcon }         from '@heroicons/react/24/outline'
import { formatDistanceStrict } from 'date-fns'

interface DownloadLogTableItemProps
{
  itemDetails?: any
}

export const DownloadLogTableItem = ( props: DownloadLogTableItemProps ): any => {
  const { itemDetails: item } = props
  const timeAgo: string       = formatDistanceStrict(
    new Date( item.created_at ),
    new Date,
    { addSuffix: true }
  )

  return (
    <tr className="hover:bg-gray-50">
      <td className="border-b border-gray-table-border p-3 w-16">
        <div className="flex items-center justify-center">
          <DocumentIcon className="w-5 h-5" />
        </div>
      </td>
      <td className="border-b border-gray-table-border p-3">
        <div className="mb-1">Download ID: { item.id }</div>
        <div><strong>{ item.name }</strong></div>
        <div>
          <strong>{ timeAgo }</strong>
          &nbsp;by <strong>{ item.user?.name }</strong> &nbsp;
          {/*<strong>location</strong>*/ }
        </div>
      </td>
    </tr>
  )
}
