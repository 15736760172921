import FileTableItem from './FileTableItem'

export default function FileTable( props: any ) {
  const { canDelete, document, refetch } = props
  let files: any[]                       = []

  // Sort files by the creation_at date desc
  if ( document.files ) {
    files = [ ...document.files ]
    files.sort( ( a: any, b: any ): number => ( a.created_at < b.created_at ) ? 1 : ( ( a.created_at > b.created_at ) ? -1 : 0 ) )
  }

  return (
    <div>
      { ( !document.files || document.files.length < 1 ) && <p>This document does not contain any files.</p> }
      { ( document.files && document.files.length > 0 ) &&
        <table className="documents-table w-full text-sm">
          <thead>
            <tr>
              <th className="w-[1%] border-b-2 border-gray-table-border p-3 px-0"></th>
              <th className="w-[90%] border-b-2 border-gray-table-border p-3 align-bottom text-left">Name</th>
              <th className="w-[9%] border-b-2 border-gray-table-border p-3"></th>
            </tr>
          </thead>
          <tbody>
            { files.map( ( item: any, idx: any ) =>
                           <FileTableItem
                             key={ item.id }
                             refetch={ refetch }
                             canDelete={ canDelete }
                             itemDetails={ item }
                             idx={ idx }
                           /> ) }
          </tbody>
        </table>
      }
    </div>
  )
}
