import { Link }  from 'react-router-dom';
import PinMe     from '../PinMe/PinMe';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { FolderIcon } from '@heroicons/react/24/solid'
import { Image }                    from '../Image'
import { assetFilePath, formatFilePathForView }            from '../../utilities';

interface SearchResultItemProps {
    document: any,
    addQueryToLocalStorage: (event: any) => void
}

export default function SearchResultItem(props: SearchResultItemProps): JSX.Element {
    const { document, addQueryToLocalStorage } = props;

    const markup = (document: any) => (
        <div className='flex items-center'>
            <div className='flex items-center gap-x-2 flex-1'>
                {document.folder ? (
                    <FolderIcon className='text-blue-secondary w-5 h-5' />
                ) : (
                    <Image 
                        src={ assetFilePath(document.files[0]?.extension)}
                        defaultSrc={ assetFilePath(document.files[0]?.extension)}
                        klass='w-5 h-5'
                    />
                )}
                <div className='flex-1'>
                    <div className='flex-1 truncate text-sm max-w-[97%]'>{ document.title }</div>
                    <Breadcrumb unique_id={ document.unique_id } />
                </div>
               
            </div>
            
        </div>
    )

    return (
        <div className='flex flex-1 items-center'>
            {document.folder ? (
                <Link 
                    data-title={ document.title }
                    data-folder={ document.folder } 
                    data-path={ determinDocumentPath(document) }
                    data-extension={ document.files[0]?.extension }
                    data-file-path={ document.files[0]?.path }
                    to={ determinDocumentPath(document) } 
                    onClick={ addQueryToLocalStorage }
                    className='flex-1 w-full'>
                    { markup(document)}
                    
                </Link>
            ) : (
                <a 
                    data-title={ document.title }
                    data-folder={ document.folder } 
                    data-path={ formatFilePathForView(document.files[0]?.path) }
                    data-extension={ document.files[0]?.extension }
                    data-file-path={ document.files[0]?.path }
                    download={document.title } 
                    href={formatFilePathForView(document.files[0]?.path)} 
                    onClick={ addQueryToLocalStorage }
                    className='flex-1 w-full'
                >
                    { markup(document)}
                </a>
            )}

            <div className='absolute right-3'>
                <PinMe document={ document } />
            </div>
        </div>
    )
}

function determinDocumentPath(document: any) {
    if (document.folder) {
        return '/documents/folder/' + document.unique_id
    }

    return '/documents/file/' + document.unique_id
}