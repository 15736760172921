import { useSelector } from 'react-redux'
import { Button }      from '../../components'
import MainLayout      from '../../layouts/MainLayout'

export default function DesignDock(): JSX.Element {
  const currentUser: any         = useSelector( ( state: any ) => state.currentUser.user )
  const brandHubPassword: string = `@B4nNaM0oN-${ currentUser?.roi_id }`
  const errorRedirectUrl: string = `${ process.env.REACT_APP_V2_HOST }/design-dock-error`

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="flex w-full mb-4">
              <header className="mb-4">
                <h1 className="sm:text-2xl text-xl font-bold">Design Dock - Login Page</h1>
              </header>
            </div>

            <div className="flex flex-col w-full">
              { currentUser &&
                <form action="https://bananamoon.roi360.co.uk/UserRemoteLogin.aspx" name="register" method="post">
                  <p className="text-muted">Banana Moon Design Dock</p>

                  <div className="pb-4">
                    <input type="hidden" name="Username" value={ currentUser.email } required />
                    <input type="hidden" name="Password" value={ brandHubPassword } required />
                    <input type="hidden" name="UserProfileEmailAddress" value={ currentUser.email } required />

                    <input name="error" type="hidden" value={ errorRedirectUrl } />
                    <input type="hidden" name="register" value="true" />
                  </div>

                  <div className="w-48">
                    <Button submittable label="Login" />
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
