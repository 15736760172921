// @ts-nocheck
import { useLazyQuery, useQuery }                      from '@apollo/client'
import { MagnifyingGlassIcon, XMarkIcon }              from '@heroicons/react/20/solid'
import { format, parseISO, subDays }                   from 'date-fns'
import { debounce }                                    from 'lodash-es'
import Papa                                            from 'papaparse'
import { useState }                                    from 'react'
import { useNavigate, useParams }                      from 'react-router-dom'
import Datepicker                                      from 'react-tailwindcss-datepicker'
import { LoadingEllipsis }                             from '../../components/Loaders/Loaders'
import Pagination                                      from '../../components/Pagination/Pagination'
import { GET_DOWNLOAD_LOGS, GET_DOWNLOAD_LOGS_TO_CSV } from '../../graphql/downloadLog'
import { DownloadLogTableItem }                        from './DownloadLogTableItem'

export const DownloadLogTable = (): any => {
    const { page, query }   = useParams()
    const [ logs, setLogs ] = useState( {} )
    const navigate          = useNavigate()

    const { loading } = useQuery( GET_DOWNLOAD_LOGS, {
        variables:   { input: { page: parseInt( page ) ?? 1, query: query ?? null } },
        onCompleted: ( { downloadLogs } ) => setLogs( downloadLogs )
    } )

    const [ fetchLogsToCSV ] = useLazyQuery( GET_DOWNLOAD_LOGS_TO_CSV )

    const setCurrentPage = ( page: number = 1 ) => navigate( `/downloads/${ query ? 'search/' + query + '/' + page : page }` )

    const debouncedSearch: any = debounce( async ( searchTerms: any ): Promise<void> => await navigate( `/downloads/search/${ searchTerms }` ), 1000 )

    const handleSearch = ( e: any ): void => {
        if ( e.target?.value?.length && e.target.value.trim().length >= 3 ) {
            debouncedSearch( e.target.value )
        }
    }

    const handleCancelSearch = (): void => navigate( `/downloads` )

    const now: Date                             = new Date
    const monthAgo: Date                        = subDays( now, 30 )
    const [ exportFromDate, setExportFromDate ] = useState(
        { startDate: monthAgo, endDate: monthAgo }
    )
    const handleExportFromDateChange            = ( newValue: any ) => setExportFromDate( newValue )

    const [ exportToDate, setExportToDate ] = useState(
        { startDate: now, endDate: now }
    )
    const handleExportToDateChange          = ( newValue: any ) => setExportToDate( newValue )

    const exportData = ( data: any, fileName: string, type: string ) => {
        // Create a link and download the file
        const blob: any            = new Blob( [ data ], { type } )
        const url: string          = window.URL.createObjectURL( blob )
        const a: HTMLAnchorElement = document.createElement( 'a' )
        a.href                     = url
        a.download                 = fileName
        a.click()
        window.URL.revokeObjectURL( url )
    }

    const handleDownloadCsv = async (): Promise<void> => {
        const fromDate: string = new Date( exportFromDate.startDate ).toISOString()
        const toDate: string   = new Date( exportToDate.startDate ).toISOString()

        if ( fromDate <= toDate ) {
            const { data } = await fetchLogsToCSV( {
                                                       variables: { input: { fromDate, toDate, type: 'document' } }
                                                   } )

            if ( data ) {
                // @ts-ignore
                const filteredData = data.downloadLogsToCSV.map( ( {
                                                                       __typename,
                                                                       updated_at,
                                                                       ...keep
                                                                   } ) => {
                    keep.created_at = format( parseISO( keep.created_at ), 'dd-MM-yyyy hh:mm:ss' )
                    return keep
                } )

                exportData(
                    Papa.unparse( filteredData ),
                    `documents_download_log_${ format( new Date, 'dd_MM_yyyy_hh_mm_ss' ) }.csv`,
                    'text/csv;charset=utf-8;'
                )
            }
        }
    }

    return (
        <>
      <div className="row flex items-center w-full bg-white rounded-full p-2">
          <div className="col flex w-full">
              <div className="flex-1 bg-blue-subtle rounded-full flex items-center px-3 relative">
                  <MagnifyingGlassIcon className="w-5 h-5" />

                  <div className="w-full rounded-full">
                      <input
                          type="search"
                          placeholder="Search..."
                          onChange={ ( e ) => handleSearch( e ) }
                          className="w-full bg-transparent border-none pl-3 text-sm font-medium"
                      />
                  </div>
              </div>
          </div>
      </div>

      <div className="row flex flex-col lg:flex-row items-center w-full bg-white rounded-xl p-4 lg:p-5 gap-4 lg:gap-5">
          <div className="col flex w-full">
              <button
                  onClick={ handleDownloadCsv }
                  type="button"
                  className="w-full md:w-auto p-0 flex items-center text-lg text-white bg-blue-secondary hover:bg-blue-secondary-darker rounded-lg px-4 py-2"
              >
                  Export CSV
              </button>
          </div>

          <div className="col flex w-full">
              <Datepicker
                  useRange={ false }
                  asSingle={ true }
                  value={ exportFromDate }
                  onChange={ handleExportFromDateChange }
              />
          </div>

          <div className="col flex w-full">
              <Datepicker
                  useRange={ false }
                  asSingle={ true }
                  value={ exportToDate }
                  onChange={ handleExportToDateChange }
              />
          </div>
      </div>

      <div className="row flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
          <div className="col flex w-full mb-8">
              <h4 className="font-medium text-xl">
                  Documents Download Log
              </h4>
          </div>

          { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

          { !loading && logs.data &&
            <>
              { query &&
                <div className="col flex w-full mb-4">
                    <p className="text-muted">Search: "{ query }"</p>
                    <button onClick={ handleCancelSearch } className="flex items-center justify-center right-2 top-2 w-6 h-6 rounded-full hover:bg-gray-100">
                        <XMarkIcon className="w-5 h-5" />
                    </button>
                </div>
              }
                <div className="col flex w-full">
                <table className="documents-table w-full text-sm">
                  <tbody>
                    { logs.data.map( ( item: any ) =>
                                         <DownloadLogTableItem
                                             key={ item.id }
                                             itemDetails={ item }
                                         /> ) }
                  </tbody>
                </table>
              </div>

              <div className="col flex justify-center mt-4 w-full">
                { ( logs.links?.totalPages > 0 ) &&
                  <Pagination
                      className="justify-content-center"
                      currentPage={ logs.links.page }
                      totalCount={ logs.links.totalResults }
                      pageSize={ logs.links.perPage }
                      onPageChange={ ( page: any ) => setCurrentPage( page ) }
                  />
                }
              </div>
            </>
          }
      </div>
    </>
    )
}
