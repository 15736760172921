import { useEffect, useState }    from 'react';
import { FolderIcon, FolderOpenIcon } from '@heroicons/react/24/solid'
import { Link }         from 'react-router-dom';
import { Image }         from '../Image'
import { XMarkIcon }    from '@heroicons/react/20/solid';
import { assetFilePath, formatFilePathForView }      from '../../utilities';

interface RecentSearchesProps {
    query: string
    loading: boolean;
    onClose?: () => void
}

export default function RecentSearches(props: RecentSearchesProps) {
    const STORAGE_KEY = 'bhm_recent_search'
    const LOGO_URL = 'https://www.bananamoon-thehub.co.uk/images/bananamoon.jpg'
    const { query, loading, onClose } = props;
    const [searches, setSearches] = useState([])
    
    const removeSearch = (event: any) => {
        event.preventDefault();
        event.stopPropagation()
        const queryKey = event.currentTarget.dataset.query
        // remove
        const existingArrayStr = localStorage.getItem(STORAGE_KEY);

        if (existingArrayStr) {
            const arr = JSON.parse(existingArrayStr).filter((s: any) => s.query !== queryKey)
            localStorage.setItem(STORAGE_KEY, JSON.stringify(arr));
            setSearches(arr)
        }
    }


    useEffect(function setRecentSearches() {
        const existingArrayStr = localStorage.getItem(STORAGE_KEY);

        if (existingArrayStr) {
            setSearches(JSON.parse(existingArrayStr).reverse())
        }

    }, [query])

    const markup = (search: any) => (
        <div className='flex items-center'>
            <div className='flex items-center gap-x-2 flex-1'>
                {search.folder === 'true' ? (
                    <FolderIcon className='text-blue-secondary w-5 h-5' />
                ) : (
                    <Image 
                        src={ assetFilePath(search.extension)}
                        defaultSrc={ assetFilePath(search.extension)}
                        klass='w-5 h-5'
                    />
                )}
                <div className='flex-1 truncate text-sm max-w-[90%]'>{ search.title }</div>
            </div>
            <div>
                <button title="Remove this search" data-query={ search.query } className='relative z-50' type='button' onClick={ removeSearch }>
                    <XMarkIcon className='w-5 h-5 text-gray-500 hover:text-black' />
                </button>
            </div>
        </div>
    )

    return (
        <div className=''>
            <div className='font-medium mt-3 pb-3 px-4 border-b'>
                Recent
            </div>
         
            {searches.length > 0 ? (
                <ul role='listbox'  className='divide-y'>
                {searches.map((search: any, idx: number) => (
                    <li key={idx} role="option" className='hover:bg-gray-50 px-4 py-2'>
                        {search.folder === 'true' ? (
                            <Link to={ search.path } onClick={ onClose }>
                                { markup(search) }
                            </Link>
                        ) : (
                            <a download={ search.title }  href={ formatFilePathForView(search.filePath) } onClick={ onClose } >
                                {markup(search)}
                            </a>
                        )}
                    </li>
                ))}
                
            </ul>
            ) : (
                <div className='flex flex-col items-center justify-center mt-3'>
                    <Image src={ LOGO_URL } defaultSrc={ LOGO_URL } />
                    <p className='text-center text-sm font-medium'>You have no recent searches</p>
                </div>
            )}
               
        </div>
    )
}