import classNames                                 from 'classnames'
import { Pin }                                    from "lucide-react"
import { Menu, Transition }                       from '@headlessui/react'
import { GlobeAltIcon, FaceSmileIcon }            from '@heroicons/react/24/solid'
import { Fragment, useEffect, useRef, useState }  from 'react'
import { PinDocument, UnPinDocument, GET_PINNED_DOCUMENTS }             from '../../graphql/document'
import { useMutation }                            from '@apollo/client'
import { useSelector }                            from 'react-redux';

interface PinMeProps {
  document: any,
  tinyIcon?: boolean;
  directUnpin?: boolean;
}


export default function PinMe(props: PinMeProps) {
    const { document, tinyIcon, directUnpin } = props
    const currentUser = useSelector((state: any) => state.currentUser.user);
    const [pinDocument, { loading: pinning }] = useMutation(PinDocument, {
      update: (cache, { data: { pinDocument } }) => updateCache(cache, pinDocument.data, true),
      //onCompleted: (data) => console.log(data),
      onError: (error) => console.log(error)
    });

    const [unpinDocument, { loading: unpinning }] = useMutation(UnPinDocument, {
      update: (cache, { data: { unpinDocument } }) => updateCache(cache, unpinDocument.data, false),
      // onCompleted: (data) => console.log(data),
      onError: (error) => console.log(error)
    });

    const updateCache = (cache: any, updatedDocument: any, pinned: boolean) => {

      const { pinnedDocuments } = cache.readQuery({
        query: GET_PINNED_DOCUMENTS,
        variables: { input: { perPage: 50}}
      });

      cache.modify({
        fields: {
          pinnedDocuments(_: any) {
             // Remove the document with the specified ID if it exists
            const updatedPinnedDocuments = pinnedDocuments.data.filter(
              (document: any) => document.id !== updatedDocument.id
            );

            if (pinned) {
              // Append the updatedDocument after removing any duplicates
              return {
                data: [...updatedPinnedDocuments, updatedDocument],
              };
            }

            return { data: updatedPinnedDocuments };
          },
        },
      });

      cache.modify({
        id: cache.identify(updatedDocument),
        fields: {
          global_pinned: () => updatedDocument.global_pinned,
          files: () => document.files,
          pinned: () => pinned
        },
      });
    };

    const isPinnedFor = (who: string) => who === "admin" ? document.global_pinned : document.pinned;

    const handlePinGlobally = () => {
      const variables = { input: { document_id: document.id, global: true } }
      
      if (!isPinnedFor('admin')) {
        pinDocument({
          variables
        })
      } else {
        unpinDocument({
          variables
        })
      }
    }
    
    const handlePinForMe = () => {
      const variables = { input: {document_id: document.id} }

      if (!isPinnedFor('me')) {
        pinDocument({
          variables
        })
      } else {
        unpinDocument({
          variables
        })
      }
    }

    if (!document) return;

    const handleDirectUnpin = () => {
      unpinDocument({
        variables: { input: {document_id: document.id, global: document.global_pinned} }
      })
    }

    const pinnMarkup = (
      <Pin className={classNames({
        "stroke-orange-600 fill-orange-600": directUnpin || document.global_pinned || document.pinned,
        "fill-gray-200 stroke-gray-200 group-hover:fill-orange-600 group-hover:stroke-orange-600": !directUnpin && !document.pinned && !document.global_pinned,
        "w-4 h-4": !tinyIcon,
        "w-3 h-3": tinyIcon
        })} />
    );


    const buttonTitle = document.pinned ? (document.global_pinned ? 'Pinned Globally' : 'Pinned for me only') : 'Not pinned'

    const markup: any = currentUser.admin && !directUnpin ? (
      <div className="">
        <Menu as="div" className="relative">
          <div className='flex'>
            <Menu.Button title={ buttonTitle } className="group">
              { pinnMarkup}
              </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-50 right-0 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 text-sm font-medium">
                <Menu.Item>
                  {({ active }) => (
                    <button
                    onClick={handlePinGlobally}
                      className={`${
                        active ? 'bg-orange-400 text-white' : 'text-gray-900'
                      } group flex w-full items-center rounded-md gap-x-2 px-2 py-2 text-sm ${isPinnedFor('admin') && 'bg-orange-600 text-white'}`}
                    >
                      <GlobeAltIcon className='w-5 h-5' />
                      Globally
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className={classNames("px-1 py-1 text-sm font-medium", {
                "hidden": document.global_pinned
              })}>
                
                <Menu.Item>
                  {({ active }) => (
                    <button
                    onClick={handlePinForMe}
                      className={`${
                        active ? 'bg-orange-400 text-white' : 'text-gray-900'
                      } group flex w-full gap-x-2 items-center rounded-md px-2 py-2 text-sm ${isPinnedFor('me') && 'bg-orange-600 text-white'}`}
                    >
                      <FaceSmileIcon className='w-5 h-5' />
                      For me
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    ) : (
      <button onClick={directUnpin ? handleDirectUnpin : handlePinForMe}>
        { pinnMarkup}
      </button>
      
    )
    // Hide pin button for regular users where the document was pinned by an admin
    if (!currentUser.admin && document.global_pinned) return;

    return markup;
}