// slice reducer
import { createSlice } from '@reduxjs/toolkit'

export const toasterSlice = createSlice({
  name: 'toaster',
  initialState: {
    show: false ,
    // When converting this file to .ts, "type" should be an enum. See Toast.js for values in the SVGs
    toasts: [], // An array of, for example: { id: GENERATE_UUID(), type: "success", message: "Email sent!" }
  },
  reducers: {
    showToaster: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.show = action.payload
    },
    setToasts: (state, action) => {
      state.toasts = action.payload;
    },
    clearToasts: (state) => {
      state.toasts = []
    },
  },
})

// Action creators are generated for each case reducer function
export const { showToaster, clearToasts, setToasts } = toasterSlice.actions

export default toasterSlice.reducer