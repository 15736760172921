import MainLayout from '../../layouts/MainLayout'

export default function Suppliers(): JSX.Element {
  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="flex w-full mb-4">
              <header className="mb-4">
                <h1 className="sm:text-2xl text-xl font-bold">Recommended Suppliers</h1>
              </header>
            </div>

            <div className="flex flex-col w-full text-sm">
              { /*Supplier Start - Eden */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Eden</h2>

                <p className="mb-4">
                  <strong>Eden Training Solutions</strong> are England’s leading Early Years training specialists, delivering bespoke apprenticeships to employers across the Childcare sector.
                </p>

                <div className="flex flex-col">
                  <p className="mb-4">Eden deliver the full suite of early years apprenticeships including:</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Early Years Practitioner (L2)</li>
                      <li>Early Years Educator (L3)</li>
                      <li>Advanced Childcare Practice & Management (L4)</li>
                      <li>Early Years Lead Practitioner (L5)</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:enquiries@eden-ts.com">enquiries@eden-ts.com</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://www.eden-ts.com/">www.eden-ts.com</a>
                    <a className="text-blue-secondary hover:underline" href="tel:08007563985">0800 756 3985</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.eden-ts.com/" target="_blank">
                      <img src="/images/suppliers/Eden-Logo-Gold-Black.png" style={ {
                        maxHeight: '80px'
                      } } alt="Eden" />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - eden */ }

              { /*Supplier Start - Day Nurseries */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Day Nurseries</h2>

                <p className="mb-4">
                  <a className="text-blue-secondary hover:underline" href="https://www.daynurseries.co.uk" target="_blank">daynurseries.co.uk</a> is the leading day nursery and nursery school review website in the UK. There are many excellent features your nursery’s online presence can benefit from as part of your subscription. You can:
                </p>

                <div className="flex flex-col">
                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Highlight positive reviews and pin useful reviews to your page</li>
                      <li>Use different emails and phone numbers for ‘job’ and ‘general’ enquiries</li>
                      <li>Showcase key photos and reviews</li>
                      <li>Boost your search ranking</li>
                      <li>Print a review QR code for parents to scan</li>
                      <li>Email review invitations from your control panel</li>
                      <li>Advertise 50 jobs per year and access 14,000 candidates with our CV search tool.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    Craig Foster<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:craig.foster@tomorrows.co.uk">craig.foster@tomorrows.co.uk</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://www.daynurseries.co.uk/">daynurseries.co.uk</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.daynurseries.co.uk/" target="_blank">
                      <img
                        src="/images/suppliers/daynurseries.svg"
                        style={ { maxWidth: '100%', maxHeight: '100px' } }
                        alt="Day Nurseries"
                      />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - Day Nurseries */ }

              { /*Supplier Start - One MSP */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">One MSP</h2>

                <p className="mb-4">OneMSP combines technical expertise with business experience to make IT easy and affordable. Affording you and your business the right functionality and control is key, helping you to keep up to date with the ever-changing technological landscape.</p>

                <div className="flex flex-col">
                  <p className="mb-4">Their comprehensive and secure service offers you:</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>24/7 monitoring of all system parts for failure</li>
                      <li>48-hour response times for queries and problems</li>
                      <li>At least one months’ worth of recordings stored on a device</li>
                      <li>Full IT support from 8am to 8pm Monday to Friday.</li>
                    </ul>
                  </div>
                </div>

                <p className="mb-4">
                  Need support? You can raise a CCTV or biometric support ticket by contacting&nbsp;
                  <a className="text-blue-secondary hover:underline" href="mailto:support@onemsp.com">
                    support@onemsp.com
                  </a>
                </p>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:support@onemsp.com">support@onemsp.com</a>
                    <a className="text-blue-secondary hover:underline" href="https://onemsp.com" target="_blank">www.onemsp.com</a>
                    <a className="text-blue-secondary hover:underline" href="tel:+443335771555">0333 577 1555</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://onemsp.com" target="_blank">
                      <img src="/images/suppliers/onemsp.jpg" style={ { maxHeight: '100px' } } alt="One MSP" />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - One MSP */ }

              { /*Supplier Start - Citation */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Citation</h2>

                <p className="mb-4">Citation offer HR and health & safety support to your company when you need it most. From ongoing changes in legislation and increasing regulatory demands to recruitment and funding issues, they understand the challenges facing nurseries. </p>

                <div className="flex flex-col md:flex-row md:space-x-8 w-full">
                  <div className="flex flex-col w-full md:w-1/2 mb-4">
                    <p className="mb-4">HR & Employment Law:</p>

                    <ul className="w-full list-disc pl-8 mb-4">
                      <li>Returning employees to work and how to manage those who are unable or unwilling to return</li>
                      <li>Grievances and how to manage those who are unable or unwilling to work</li>
                      <li>Performance and sickness absence management</li>
                      <li>Redundancies and business restructuring.</li>
                    </ul>
                  </div>

                  <div className="flex flex-col w-full md:w-1/2 mb-4">
                    <p className="mb-4">Health and safety:</p>

                    <ul className="w-full list-disc pl-8 mb-4">
                      <li>Policies, procedures and handbooks</li>
                      <li>Legally required fire and electrical checks</li>
                      <li>RIDDOR-reportable incidents.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    Matthew Wharton<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:matthewwharton@citation.co.uk">matthewwharton@citation.co.uk</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://www.citation.co.uk/nurseries">citation.co.uk/nurseries</a>
                    <a className="text-blue-secondary hover:underline" href="tel:03458441111">0345 844 1111</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.citation.co.uk/nurseries" target="_blank">
                      <img src="/images/suppliers/citation.svg" style={ {
                        maxWidth:  '50%',
                        maxHeight: '100px'
                      } } alt="Citation" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - Citation */ }

              { /*Supplier Start - 2 Circles Consulting */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">2 Circles Consulting</h2>

                <p className="mb-4">2 Circles Consulting specialise in bespoke voice, mobile and broadband packages tailored to suit your business needs. Why 2 Circles? We are BFA affiliated and guarantee:</p>

                <div className="flex flex-col">
                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Award-winning customer service</li>
                      <li>A dedicated Account Manager as your one point of contact</li>
                      <li>Like-for-like comparisons</li>
                      <li>Cost savings</li>
                      <li>98% customer retention rate.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:jess@2circlesconsulting.com">jess@2circlesconsulting.com</a>
                    <a className="text-blue-secondary hover:underline" href="tel:03456200222">0345 620 0222</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.2circlesconsulting.com/" target="_blank">
                      <img src="/images/suppliers/2circles.png" style={ {
                        maxHeight: '120px'
                      } } alt="2 Circles Consulting" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - 2 Circles Consulting */ }

              { /*Supplier Start - FireNet */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">FireNet</h2>

                <p className="mb-4">FireNet Security are an established fire protection company. At FireNet, we use the best equipment and our extensive engineering knowledge to offer effective protection to people and property. Our staff are comprehensively trained, experienced and committed to delivering the best service all year round, thanks to our 24/7, 365 days a year, emergency callout service.</p>

                <div className="flex flex-col">
                  <p className="mb-4">Our range of available services include:</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Fire risk assessment consultation</li>
                      <li>Supply and service of fire extinguishers</li>
                      <li>Fire alarm installation, maintenance and fault calls</li>
                      <li>Emergency lighting</li>
                      <li>CCTV, security and door entry/door access systems</li>
                      <li>Nurse call systems</li>
                      <li>Gas suppression systems.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    Toby Mynett<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:info@firenetsecurity.co.uk">info@firenetsecurity.co.uk</a>
                    <a className="text-blue-secondary hover:underline" href="tel:07932032466">07932032466</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.firenetsecurity.co.uk/" target="_blank">
                      <img src="/images/suppliers/firenet.png" style={ {
                        maxHeight: '120px'
                      } } alt="FireNet" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - FireNet */ }

              { /*Supplier Start - Blossom Educational */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Blossom Educational</h2>

                <p className="mb-4">Giving every parent peace of mind with the Parent App.</p>
                <p className="mb-4">We recognise the importance of spending as much time as possible with your child while they attend our nursery and, perhaps more importantly, making sure that we are tracking their individual development with the Early Years Foundation Stages. Our ongoing investment in nursery software management helps us achieve just that.</p>
                <p className="mb-4">Our custom-made software is designed to make capturing observations of your child’s development easy, with the ability to share precious moments at the click of a button.</p>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    Blossom Support Desk <br />
                    <a className="text-blue-secondary hover:underline" href="tel:+441923545200">01923 545 200</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://blossomeducational.com/" target="_blank">
                      <img src="/images/suppliers/blossom-educational.jpg" style={ {
                        maxHeight: '136px'
                      } } alt="Blossom Educational" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - Blossom Educational */ }

              { /*Supplier Start - Hope Education */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Hope Education</h2>

                <p className="mb-4">Here at Hope, our goal is to be the daily inspiration that makes life easier for early years practitioners and educators, we want to help you in doing the most important job in the world!</p>
                <p className="mb-4">We strive to provide you with quality resources you need and want, excellent service and creative inspiration to help you succeed.</p>

                <div className="flex flex-col">
                  <p className="mb-4">As a supplier to you, we offer a range of benefits.</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Discount 20%</li>
                      <li>Bespoke Banana Moon Core list</li>
                      <li>Next day delivery foc if ordered by 2pm</li>
                      <li>No minimum order charges</li>
                      <li>Free nursery room design service</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    Account Manager: Judy Whitehead<br />
                    <div>
                      Email: <a className="text-blue-secondary hover:underline" href="mailto:judy.whitehead@findel.co.uk">judy.whitehead@findel.co.uk</a>
                    </div>

                    <div>
                      Phone: <a className="text-blue-secondary hover:underline" href="tel:+447753776555">0775 377 6555</a>
                    </div>

                    <br />

                    <div className="break-words">
                      Website link:
                      <a className="text-blue-secondary hover:underline" target="_blank" href="https://www.hope-education.co.uk/early-years-resources-inspiration">https://www.hope-education.co.uk/early-years-resources-inspiration</a>
                    </div>

                    <br />

                    <div className="break-words">
                      Latest digital catalogue:<br />
                      <a className="text-blue-secondary hover:underline" target="_blank" href="https://content.yudu.com/web/43fkl/0A43zj5/NursFavourites2023/html/index.html">https://content.yudu.com/web/43fkl/0A43zj5/NursFavourites2023/html/index.html</a>
                    </div>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.hope-education.co.uk/" target="_blank">
                      <img src="/images/suppliers/big-Hope-Logo.jpg" style={ {
                        maxHeight: '100px'
                      } } alt="Hope Education" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - Hope Education */ }

              { /*Supplier Start - UK Merchandising */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">UK Merchandising</h2>

                <p className="mb-4">UK Merchandising is here for all of your branded merchandise needs. We think global but act local, with expertise in worldwide distribution. From hats to bags and uniforms to cuddly toys, we can help bring your brand to life.</p>

                <div className="flex flex-col">
                  <p className="mb-4">You can expect:</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>A team of experienced specialists</li>
                      <li>In-house production at our state-of-the-art facilities</li>
                      <li>A selection of hundreds of thousands of clothing and merchandise ideas</li>
                      <li>Quick delivery.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    Anil Sharma<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:sales@ukmerchandising.co.uk">sales@ukmerchandising.co.uk</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://ukmerchandising.co.uk/" target="_blank">
                      <img src="/images/suppliers/uk-merchandising.png" style={ {
                        maxWidth:  '80%',
                        maxHeight: '100px'
                      } } alt="UK Merchandising" />
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              { /*Supplier End - UK Merchandising */ }

              { /*Supplier Start - Warwick signs */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Warwick signs</h2>

                <p className="mb-4">
                  Warwick Signs covers all aspects of signage as one of the UK’s leading sign makers, creating your artwork in their in-house studio. We specialise in:
                </p>

                <div className="flex flex-col">
                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Vehicle advertising: Vehicle wrapping, fleet livery, magnetic/removable signs and bespoke vehicle designs.</li>
                      <li>Business awareness: Illuminated signage, 3D lettering signage, fascia and factory signs, window graphics and street signage.</li>
                      <li>General signage: Acrylic signs, health and safety signage, direction signs, door signs and internal office signage.</li>
                      <li>Business promos: Large-format printing, PVC and mesh banners, flags and pop-up banners, exhibition stands, business card and logo design service.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:info@warwicksigns.co.uk">info@warwicksigns.co.uk</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://warwicksigns.co.uk/">warwicksigns.co.uk</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://warwicksigns.co.uk/" target="_blank">
                      <img src="/images/suppliers/warwick-signs.jpeg" style={ {
                        maxHeight: '100px'
                      } } alt="Warwick signs" />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - Warwick signs */ }

              { /*Supplier Start - Rawcliffe & Co */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Rawcliffe & Co</h2>

                <p className="mb-4">
                  Rawcliffe & Co are chartered accountants, offering accountancy and taxation services. Our highly qualified team of experts are here to help you and your business ensure compliance, boost profits and drive growth. We can offer support with:
                </p>

                <div className="flex flex-col">
                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Financial advisory</li>
                      <li>Tax consultancy</li>
                      <li>Bookkeeping</li>
                      <li>Audits</li>
                      <li>App integration and cloud migration.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:info@rawcliffeco.com">info@rawcliffeco.com</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://rawcliffe.co.uk/">rawcliffe.co.uk</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://rawcliffe.co.uk/" target="_blank">
                      <img src="/images/suppliers/rawcliffe.jpeg" style={ {
                        maxWidth:  '80%',
                        maxHeight: '100px'
                      } } alt="Rawcliffe & Co" />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - Rawcliffe & Co */ }

              { /*Supplier Start - NoodleNow! */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">NoodleNow!</h2>

                <p className="mb-4">
                  NoodleNow! provide 24/7 online training in a variety of areas, with courses that are high quality and low cost – including child development, child protection, children’s mental health, health and nutrition, physical development and safeguarding children.
                </p>

                <div className="flex flex-col">
                  <p className="mb-4">NoodleNow! has:</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>100+ CPD certified courses</li>
                      <li>12 months’ unlimited course usage</li>
                      <li>24/7 online support</li>
                      <li>No contracts or subscriptions</li>
                      <li>Unique admin account for easy stats access</li>
                      <li>A new course added every month.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:hello@noodlenow.co.uk">hello@noodlenow.co.uk</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://noodlenow.co.uk/">noodlenow.co.uk</a>
                    <a className="text-blue-secondary hover:underline" href="tel:+443334003424">0333 400 3424</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://noodlenow.co.uk/" target="_blank">
                      <img src="/images/suppliers/NoodleNow-Logo.png" style={ {
                        maxWidth:   '80%',
                        maxHeight:  '100px',
                        background: '#0274be'
                      } } alt="NoodleNow!" />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - NoodleNow! */ }

              { /*Supplier Start - Boogie Mites */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Boogie Mites</h2>

                <p className="mb-4">
                  Boogie Mites was born from a love of music and a passion for sharing it with those who benefit from it most: children! Regular music has been proven to help develop strong neural connections across the whole brain, and Boogie Mites aims to apply this knowledge through their Boogie Mites music education programmes.
                </p>

                <div className="flex flex-col">
                  <p className="mb-4">In addition to classes for children, Boogie Mites also offers other services, including:</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Practitioner training</li>
                      <li>School-ready transition support courses</li>
                      <li>Parent education courses</li>
                      <li>Early Years settings workshops</li>
                      <li>Music to support SEN programme.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" href="mailto:enquiry@boogiemites.co.uk">enquiry@boogiemites.co.uk</a>
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://boogiemites.co.uk/">boogiemites.co.uk</a>
                    <a className="text-blue-secondary hover:underline" href="tel:+442392817274">023 9281 7274</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://boogiemites.co.uk/" target="_blank">
                      <img src="/images/suppliers/boogiemites-logo.png" style={ {
                        maxWidth:  '100%',
                        maxHeight: '200px'
                      } } alt="Boogie Mites" />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - Boogie Mites */ }

              { /*Supplier Start - Pyjama Drama */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Pyjama Drama</h2>

                <p className="mb-4">Pyjama Drama educate young children through drama and imaginative play to develop their life skills. We offer classes, parties and performances for children up to seven years, plus award-winning drama and imaginative play training and resources for early years practitioners.</p>

                <div className="flex flex-col">
                  <p className="mb-4">We offer imaginative play classes in nurseries for children aged six months to four years. These classes help children develop many skills, including:</p>

                  <div className="flex ml-4 mb-4">
                    <ul className="w-full list-disc pl-4 mb-4">
                      <li>Making new friends</li>
                      <li>Managing conflict in relationships</li>
                      <li>Communicating their ideas, thoughts and feelings</li>
                      <li>Managing their emotions and understanding others’ emotions</li>
                      <li>Solving simple problems for themselves</li>
                      <li>Making decisions with confidence.</li>
                    </ul>
                  </div>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://www.pyjamadrama.com/">www.pyjamadrama.com</a>
                    <a className="text-blue-secondary hover:underline" href="tel:+447944658232">07944 658 232</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://www.pyjamadrama.com/" target="_blank">
                      <img src="/images/suppliers/pyjamadrama-logo.png" style={ {
                        maxHeight: '100px'
                      } } alt="Pyjama Drama" />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - Pyjama Drama */ }

              { /*Supplier Start - Superspace */ }
              <div className="flex w-full flex-col justify-center p-4">
                <h2 className="text-2xl font-medium mb-2">Superspace</h2>

                <div className="flex flex-col">
                  <p className="mb-4">Superspace is the life-sized ‘everything toy’. A modular, magnetic play space designed to let kids' imaginations run free. Creative play is a fun way of helping children develop their motor skills and solve problems, and it helps adults learn how children play together.</p>
                  <p className="mb-4">With unlimited possibilities for different shape combinations, the Superspace toy can become anything children want it to be.</p>
                  <p className="mb-4">Superspace are Good Toy Guide accredited and have received several awards, including ‘Best new product to Market 2023’ and ‘Best innovative children’s toy’ from Lovedbychildren in 2023, plus a ‘National parenting product’ award, gold at the Dadsnet Awards and gold at the Project Baby Toy Awards in 2022.</p>
                </div>

                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex flex-col font-bold w-full md:w-1/2">
                    Contact:<br />
                    <a className="text-blue-secondary hover:underline" target="_blank" href="https://getsuperspace.co.uk/">getsuperspace.co.uk</a>
                  </div>

                  <div className="flex justify-center w-full md:w-1/2">
                    <a className="flex justify-center items-center mt-4 md:mt-0" href="https://getsuperspace.co.uk/" target="_blank">
                      <img src="/images/suppliers/superspace-logo.png" style={ {
                        maxHeight: '100px'
                      } } alt="Superspace" />
                    </a>
                  </div>
                </div>

              </div>
              <hr />
              { /*Supplier End - Superspace */ }
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
