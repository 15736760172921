import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import createAxiosInstance  from '../../utilities/axios';
import NewsArticle from "./NewsArticle";

export default function LatestNews() {
    const currentUser = useSelector((state: any) => state.currentUser.user);
    const [items, setItems] =  useState([]);

    const getLatestProducts = async () => {
        const Api = createAxiosInstance(process.env.REACT_APP_V1_HOST);
    
        try {
            const { data } = await Api.get('/api/v2-latest-news');
            setItems(data);
        } catch (error) {
            console.log("Error getting stats", error)
        }
    }

    useEffect(() => {
       if (currentUser) {
        getLatestProducts()
       }
    }, [currentUser]);

    if (items.length === 0) return
    
    return (
        <section className="LatestNews space-y-3">
            <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">Latest news articles</h3>
                <nav>
                    <ul className="hidden flex items-center gap-x-4 text-sm font-medium">
                        <li><a>All</a></li>
                        <li><a>News</a></li>
                        <li><a>Kids</a></li>
                    </ul>
                </nav>
            </div>

            <div className="grid lg:grid-cols-3 grid-cols-2 lg:gap-4 gap-2">
                {items.map((news: any) => <NewsArticle key={news.id} {...news} />)}
            </div>
        </section>
    )
}