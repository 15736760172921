import { ArrowSmallLeftIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

export const ToggleButton = ({ isOpen, setIsOpen }) => {
    return (
        <button 
            onClick={() => setIsOpen(!isOpen)} 
            className="group-hover:visible invisible w-5 h-5 hover:bg-gray-100 rounded-full text-black bg-white shadow-md absolute -right-2 top-2 z-[999]">
            <ArrowSmallLeftIcon className={classNames('text-black', { 'rotate-180': isOpen })} />
        </button>
    )
}