import React, { useState, useEffect } from 'react';

type ImageProps = {
  src: string;
  defaultSrc: string;
  alt?: string;
  klass?: string;
};

export const Image: React.FC<ImageProps> = ({ src, defaultSrc, alt, klass }) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageError = () => {
    setImageSrc(defaultSrc);
  };

  /**
   * Always update when parent is updated or when src is changed.
   */
  useEffect(() => {
    setImageSrc(src)
  }, [src])

  return <img className={ klass } loading="lazy" src={imageSrc} onError={handleImageError} alt={alt} />;
};
