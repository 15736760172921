import MainLayout from "../../layouts/MainLayout"

export default function NurserySupport() {
    return (
        <MainLayout>
            <iframe
              style={ { width: '100%', height: '1830px', border: 0 } }
              src="https://outlook.office365.com/owa/calendar/TrainingCalendar@bananamoonfranchise.com/bookings/"
            />
        </MainLayout>
    );
}