import PropTypes from "prop-types";

import { GENERATE_UUID } from "../../utilities";
import { DataTableMenuItem } from "./DataTableMenuItem";

/**
 * Data Table
 *
 * Data tables are used to organize and display all information from a data set.
 * While a data visualization represents part of data set, a data table lets
 * you view details from the entire set. This helps you compare
 * and analyze the data
 *
 * @param {*} props
 * @returns
 */
export const DataTable = (props) => {
  const { headings, rows } = props;


  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {headings.map((heading) => (
              <th key={heading} scope="col" className="px-6 py-3">
                {heading}
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((_rows, index) => (
            <tr
              key={GENERATE_UUID()}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              {_rows.map((value) => (
                <td key={GENERATE_UUID()} className="px-6 py-4">
                  {value}{" "}
                </td>
              ))}
              <td>
                <DataTableMenuItem 
                    actions={props.actions}
                    isLastElement={rows.length === (index + 1)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};



DataTable.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.exact({
        icon: PropTypes.node,
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired
      })).isRequired,
};
