import MainLayout from '../../layouts/MainLayout'

export default function BmPlatform(): JSX.Element {
  const v2BaseUrl: string = process.env.REACT_APP_V2_HOST

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="flex w-full mb-4">
              <header className="mb-4">
                <h1 className="sm:text-2xl text-xl font-bold">BM Platform</h1>
              </header>
            </div>

            <div className="flex flex-col w-full">
              <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8">
                <a
                  className="flex w-full md:w-1/3 justify-center py-6 px-8 md:px-12 bg-blue-secondary text-white rounded-lg"
                  href="/bm-platform/faq"
                >
                  <div className="text-center whitespace-nowrap">
                    FAQ
                  </div>
                </a>

                <a
                  className="flex w-full md:w-1/3 justify-center py-6 px-8 md:px-12 bg-blue-secondary text-white rounded-lg"
                  href="/bm-platform/help-centre"
                >
                  <div className="text-center whitespace-nowrap">
                    Help Centre
                  </div>
                </a>

                <a
                  className="flex w-full md:w-1/3 justify-center py-6 px-8 md:px-12 bg-blue-secondary text-white rounded-lg"
                  href="/Banana_Moon_Finance_User_Guide.pdf"
                  target="_blank"
                >
                  <div className="text-center whitespace-nowrap">
                    Finance User Guide
                  </div>
                </a>
              </div>

              <hr className="my-8" />

              <div className="flex flex-col md:flex-row mb-4 md:mb-2">
                <div className="whitespace-nowrap w-full md:w-28">Email:</div>
                <div className="text-blue-secondary hover:underline">
                  <a href="mailtosupport@blossomeducational.com" target="_blank">support@blossomeducational.com</a>
                </div>
              </div>

              <div className="flex flex-col md:flex-row mb-4 md:mb-2">
                <div className="whitespace-nowrap w-full md:w-28">Telephone:</div>
                <div className="text-blue-secondary hover:underline">
                  <a href="tel:02039820300" target="_blank">0203 9820 300</a>
                </div>
              </div>

              <div className="flex flex-col md:flex-row mb-4 md:mb-2">
                <div className="whitespace-nowrap w-full md:w-28">Support:</div>
                <div className="text-blue-secondary hover:underline">
                  <a href="http://blossomeducational-2694700.hs-sites.com/knowledge" target="_blank">http://blossomeducational-2694700.hs-sites.com/knowledge</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
