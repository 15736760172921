import { useState, useRef, useEffect }      from 'react';
import { UploadCloud }                      from 'lucide-react';
import Dropzone                             from 'react-dropzone';
import { Button }                           from '../Button';
import Api                                  from '../../utilities/axios';
import { assetFilePath }                    from '../../utilities';
import { Image }                            from '../Image';
import { XCircleIcon }                      from '@heroicons/react/20/solid';
import { CREATE_DOCUMENT_FILES }            from '../../graphql/document';
import { useMutation }                      from '@apollo/client';
import {  useDispatch, useSelector }        from 'react-redux';
import { showToaster, setToasts }           from '../../features/toaster/toasterSlice';

interface FileDropzoneProps {
    documentUniqueId?: string;
    newDocument?: boolean;
    helpText?: string;
    refetch?: () => void
}

export default function FileDropzone(props: FileDropzoneProps) {
    const { helpText, documentUniqueId, newDocument, refetch } = props;
    const [files, setFiles] = useState<any[]>([]);
    const currentUser = useSelector((state: any) => state.currentUser.user);
    const [loading, setLoading] = useState<boolean>(false);
    const ref: any = useRef();
    const dispatch = useDispatch()
    const [ uploadFiles, { loading: uploading} ] = useMutation(CREATE_DOCUMENT_FILES, {
        //update: (cache, { data: { deleteSetAside }}) => returnThreadsCache(cache, deleteSetAside.data),
        onCompleted: (data) => handleCompleted(data),
        onError: (error) => handleError(error),
    });

    const handleCompleted = (data: any) => {
        setFiles([])
        setLoading(false);
        dispatchMessage('success', 'File uploaded successfully' )
        
        if (refetch) {
            refetch()
        }
    }

    const dispatchMessage = (type: string, message: string) => {
        dispatch(setToasts([
          {id: '1', type, message}
        ]))
        dispatch(showToaster(true));
      }

      const handleError = (err: any) => {
        dispatchMessage('error', 'File not uploaded. Please try again')
        setLoading(false);
      }
    const handleFileUpload = async () => {
        setLoading(true);
        
        const baseUrl = process.env.REACT_APP_UPLOAD_HOST;
        // Create a FormData object to send the files
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('file', file);
        });

        try {
        const response = await Api(baseUrl, 'multipart/form-data').post('/', formData);
        
        // Data, an array of:
        // mime_type, name, path, extension, size
        const { data } = response

        uploadFiles({
            variables: { input: { document_unique_id: documentUniqueId, fileData: data, new_document: newDocument }}
        })

        } catch (error) {
            // Handle error or show an error message
            console.error('Error uploading files:', error);
        }
    };

    const removeFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };


    if (!currentUser?.admin) return;

  return (
    <div className='space-y-3 text-center'>
      <div className='cursor-pointer no-select p-6 border bg-gray-100 rounded-md'>
        <Dropzone onDrop={(attachedFiles) => setFiles([...files, ...attachedFiles])}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input ref={ref} {...getInputProps()} />
                <div className='flex items-center justify-center flex-col space-y-2'>
                  <UploadCloud className='w-12 h-12' />
                  <p className='text-sm font-medium'>Drag 'n' drop or click to select files</p>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        {helpText && <p className='text-xs text-gray-500'>{helpText}</p>}
      </div>

      {files.length > 0 && (
        <div className='pb-2 border-b flex items-center flex-wrap gap-3'>
          {files.map((file: any, index: number) => (
            <article title={file.name} className='flex items-center flex-col' key={index}>
              <div className='w-10 relative h-10 rounded-md relative'>
                <button
                  title='Remove'
                  className='absolute -right-1 -top-1 bg-white shadow rounded-full'
                  onClick={() => removeFile(index)} // Add onClick handler for removing the file
                >
                  <XCircleIcon className='w-4 h-4' />
                </button>
                <Image
                  src={assetFilePath(getExt(file.type))}
                  defaultSrc={assetFilePath(getExt(file.type))}
                />
              </div>
              <small className='text-xs truncate w-12'>{file.name}</small>
            </article>
          ))}
        </div>
      )}

      <Button 
        auto
        disabled={ files.length === 0}
        label='Upload' loading={ loading || uploading } onClick={handleFileUpload} />
    </div>
  );
}

function getExt(mimeType: string) {
  return mimeType.split('/').reverse()[0];
}
