import { Route, Routes } from 'react-router-dom'
import MainLayout                     from '../../layouts/MainLayout'
import { DownloadLogTable }           from './DownloadLogTable'

export const DocumentsDownloadLog = (): any => {
    return (
        <MainLayout>
            <div className="max-w-screen-3xl mx-auto w-full">
                <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">
                    <Routes>
                        <Route path="/" element={ <DownloadLogTable /> } />
                        <Route path="/:page?" element={ <DownloadLogTable /> } />
                        <Route path="/search/:query" element={ <DownloadLogTable /> } />
                        <Route path="/search/:query/:page?" element={ <DownloadLogTable /> } />
                    </Routes>
                </div>
            </div>
        </MainLayout>
    )
}
