import { gql } from "@apollo/client";

const QuestionFragment = gql`
    fragment QuestionData on Question {
        id
        title
        slug
        body
        sticky
        created_at
        updated_at
    }
`;

export const GET_FORUM_QUESTIONS = gql`
    query Questions($input: QueryQuestionsInput!) {
        questions(input: $input) {
            data {
                ...QuestionData
                answers {
                    id
                }
                topic {
                    id
                    title
                }
                author {
                    id
                    name
                }
            }
        }
    }
    ${QuestionFragment}
`;