// slice reducer
import { createSlice } from '@reduxjs/toolkit'

export const userViewSlice = createSlice({
  name: 'userView',
  initialState: {
    userView: false,
    refetch: false,
  },
  reducers: {
    setView: (state) => {
      state.userView = !state.userView;
      state.refetch = !state.refetch
    }
  },
})

// Action creators are generated for each case reducer function
export const { setView } = userViewSlice.actions

export default userViewSlice.reducer