// slice reducer
import { createSlice } from '@reduxjs/toolkit'

export const noteSlice = createSlice({
  name: 'note',
  initialState: {
    editing: false,
  },
  reducers: {
    setNoteEditing: (state, action) => {
      state.editing = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setNoteEditing } = noteSlice.actions

export default noteSlice.reducer