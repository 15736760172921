import classNames from "classnames";
import { useRef } from "react";
import './ColourPicker.css'

interface ColourPickerProps {
    setColour: (string: any) => void,
    onFormSubmit: (event: any) => void,
    selectedColour: string;
}

export default function ColourPicker(props: ColourPickerProps) {
    const { selectedColour, setColour, onFormSubmit } = props
    const ref: any = useRef()
    const colours = [
        { value: "white"},
        { value: "yellow"},
        { value: "orange"},
        { value: "pink"},
        { value: "red"},
        { value: "purple"},
        { value: "blue"},
        { value: "green"},
        { value: "brown"},
        { value: "grey"}
    ];
    return (
        <div className="ColourPicker">
            <details ref={ref} className="ColourPicker__details">
                <summary className="ColourPicker__details_summary">
                    <img src="https://cdn-bmh-staging.s3.eu-west-2.amazonaws.com/assets/colour-wheel.webp" alt="Pick a colour!" title="Pick a colour!" className="w-3 h-3 opacity-[0.33] hover:opacity-[1] cursor-pointer" />
                </summary>
                <div className="ColourPicker__content w-[10rem] absolute right-0 border rounded-md shadow-lg bg-white z-50 p-2 text-sm font-medium">
                    <h4 className="whitespace-nowrap text-xs mb-2 text-center">Pick a colour!</h4>
                    <form className="flex items-center flex-col" onSubmit={(e: any) => {
                        e.preventDefault();
                        onFormSubmit(e)
                        ref.current.open = false
                    }}>
                        <div className="grid grid-cols-5 gap-1 mb-3">
                           {colours.map((colour: any) => (
                                <label key={colour.value} className="ColourPicker__field block w-6 h-6 rounded-full relative z-1 overflow-hidden cursor-pointer">
                                    <input 
                                        type="radio" 
                                        name="colour" 
                                        value={ colour.value }
                                        checked={ selectedColour === colour.value }
                                        onChange={() => setColour(colour.value)}
                                        className="ColourPicker__input absolute hidden opacity-0 z-0" 
                                    />
                                    <span className={`ColourPicker__swash block-colour--${colour.value} block w-full h-full`}  />
                                </label>
                           ))}
                           
                        </div>
                        <button className="text-xs w-fit font-medium px-3 py-1 border bg-white rounded-full">Save</button>
                    </form>
                </div>
            </details>
        </div>
    )
}