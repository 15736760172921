import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import {TextField, Button} from "../../components";
import createAxiosInstance from '../../utilities/axios';

export default function PasswordReser() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const onSubmit = (event) => {
        setLoading(true);

        event.preventDefault();
      
        if (token) {
            changePassword();
        } else {
            requestPasswordReset();
        }

       

    }

    const label = token ? "Change my password" : "Send me password reset link";

    const changePassword = () => {
        const Api = createAxiosInstance(process.env.REACT_APP_V1_HOST);

        Api.post('/api/password/reset', {
            token,
            email,
            password,
            password_confirmation: confirmPassword
         })
        .then(res => {
            window.location.href = '/login'
        })
        .catch(error => {
            setLoading(false);
            console.log(error);
        })
    }

    const requestPasswordReset = () => {
        const Api = createAxiosInstance(process.env.REACT_APP_V1_HOST);

        Api.post('/api/password/email', { email })
        .then(res => {
            setMessage('Please check your email for the reset instructions.')
        })
        .catch(error => {
            setLoading(false);
            console.log(error);
        })
    }

    useEffect(() => {
        const token = getToken();

        if (token) {
            setToken(token)
        }
    }, []);

    const formMarkup = (
        <form className="space-y-6" onSubmit={onSubmit}>
                <TextField
                    isRequired
                    value={email}
                    label="Email address" 
                    name="email" 
                    type="email"
                    onChange={setEmail}
                />

                {token && (
                    <> 
                        <TextField
                            isRequired
                            value={password}
                            label="New password" 
                            name="password" 
                            type="password"
                            onChange={setPassword}
                        />

                        <TextField
                            isRequired
                            value={confirmPassword}
                            label="Confirm password" 
                            name="password" 
                            type="password"
                            onChange={setConfirmPassword}
                        />
                    </>
                )}

                
                <div>
                    <Button loading={ loading } submittable label={ label } />
                </div>
            </form>
    );

    const messageMarkup = (
        <div className="flex items">
            <p className="p-6 bg-green-500 text-white rounded-md">{ message } </p>
        </div>
    )

    return (
        <AuthLayout heading="Password Reset">
           { message ? messageMarkup : formMarkup }
        </AuthLayout>
    );
}

function getToken() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('token');
}