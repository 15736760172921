import { gql } from '@apollo/client'

const DocumentFileFragment = gql`
    fragment DocumentFileData on DocumentFile {
      id
      unique_id
      document_id
      size
      mime_type
      extension
      version
      latest
      author
      name
      path
      draft
      publish
      created_at
      updated_at
    }
`

const DocumentFragment = gql`
    fragment DocumentData on Document {
        id
        unique_id
        parent_id
        creator
        name
        slug
        title
        about
        folder
        draft
        publish
        global_pinned
        email_notification
        notification_sender_uuid
        notification_sender_name
        notification_date
        colour
        pinned
        expire
        created_at
        updated_at
        files {
          ...DocumentFileData
        }
    }
    ${ DocumentFileFragment }
`

const LinksFragment = gql`
    fragment LinksData on PaginatorLinks {
        totalResults
        totalPages
        page
        perPage
        prevPage
        nextPage
    }
`

export const GET_DOCUMENT_FOLDERS = gql`
  query Documents {
      documentFolders {
        ...DocumentData
      }
    }
    ${ DocumentFragment }
`

export const GET_DOCUMENTS = gql`
  query Documents($input: QueryDocumentsInput!) {
      documents(input: $input) {
          data {
            ...DocumentData
            files {
              ...DocumentFileData
            }
          }
          links {
            ...LinksData
          }
      }
    }
    ${ DocumentFragment }
    ${ DocumentFileFragment }
    ${ LinksFragment }
`

export const GET_DOCUMENT_BY_ID = gql`
  query Documents($input: QueryDocumentByUidInput!) {
    documentByUid(input: $input) {
        ...DocumentData
    }
  }
  ${ DocumentFragment }
`

export const GET_PINNED_DOCUMENTS = gql`
  query PinnedDocuments($input: QueryPinnedDocumentsInput!) {
      pinnedDocuments(input: $input) {
          data {
            ...DocumentData
          }
      }
    }
    ${ DocumentFragment }
`

export const PinDocument = gql`
    mutation PinDocument($input: MutationPinDocumentInput!) {
      pinDocument(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationPinDocumentSuccess {
          data {
            ...DocumentData
          }
        }
      }

    }
    ${ DocumentFragment }
`;

export const UnPinDocument = gql`
    mutation UnpinDocument($input: MutationUnpinDocumentInput!) {
      unpinDocument(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationUnpinDocumentSuccess {
          data {
            ...DocumentData
          }
        }
      }

    }
    ${ DocumentFragment }
`;

export const SET_DOCUMENT_COLOUR = gql`
    mutation SetDocumentColour($input: MutationSetDocumentColourInput!) {
      setDocumentColour(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationSetDocumentColourSuccess {
          data {
            ...DocumentData
          }
        }
      }
    }
    ${ DocumentFragment }
 `;

 export const DOCUMENT_LOCATIONS = gql`
 query DocumentLocations($input: QueryDocumentLocationsInput!) {
     documentLocations(input: $input) {
       __typename
       id
       country
       document_id
       location_unique_id
     }
   }
`;

export const CREATE_DOCUMENT_LOCATION = gql`
   mutation CreateDocumentLocation($input: MutationCreateDocumentLocationInput!) {
     createDocumentLocation(input: $input) {
       __typename
       ... on FieldError {
         field
         message
       }
       ... on MutationCreateDocumentLocationSuccess {
         data
       }
     }
   }
`;



export const CREATE_DOCUMENT_FOLDER = gql`
    mutation CreateDocumentFolder($input: MutationCreateDocumentFolderInput!) {
      createDocumentFolder(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationCreateDocumentFolderSuccess {
          data {
            ...DocumentData
          }
        }
      }
    }
    ${ DocumentFragment }
`

export const UPDATE_DOCUMENT_FOLDER = gql`
    mutation UpdateDocumentFolder($input: MutationUpdateDocumentFolderInput!) {
      updateDocumentFolder(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationUpdateDocumentFolderSuccess {
          data {
            ...DocumentData
          }
        }
      }
    }
    ${ DocumentFragment }
`

export const DELETE_DOCUMENT_FOLDER = gql`
    mutation DeleteDocument($input: MutationDeleteDocumentInput!) {
        deleteDocument(input: $input) {
          __typename
          ... on FieldError {
            field
            message
          }
          ... on MutationDeleteDocumentSuccess {
            data
          }
        }
    }
`

export const CREATE_DOCUMENT_FILES = gql`
    mutation CreateDocumentFiles($input: MutationCreateDocumentFilesInput!) {
      createDocumentFiles(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationCreateDocumentFilesSuccess {
          data {
            ...DocumentFileData
          }
        }
      }
    }
    ${ DocumentFileFragment }
`

export const DELETE_DOCUMENT_FILE = gql`
   mutation DeleteDocumentFile($input: MutationDeleteDocumentFileInput!) {
     deleteDocumentFile(input: $input) {
       __typename
       ... on FieldError {
         field
         message
       }
       ... on MutationDeleteDocumentFileSuccess {
         data
       }
     }
   }
`;

export const RENAME_DOCUMENT_FILE = gql`
   mutation RenameDocumentFile($input: MutationRenameDocumentFileInput!) {
     renameDocumentFile(input: $input) {
       __typename
       ... on FieldError {
         field
         message
       }
       ... on MutationRenameDocumentFileSuccess {
         data {
          ...DocumentFileData
         }
       }
     }
   }
   ${ DocumentFileFragment }
`;

// export const UPLOAD_DOCUMENT = gql`
//     mutation UploadDocument($input: MutationUploadDocumentInput!) {
//         updateDocument(input: $input) {
//             ...DocumentData
//         }
//     }
//     ${DocumentFragment}
// `;
