import { gql } from "@apollo/client";

export const LOGIN_2FA = gql`
  mutation Login2FA($input: MutationLogin2FAInput!) {
    login2FA(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on ValidationErrors {
        fields {
          field
          messages
        }
        message
      }
      ... on MutationLogin2FASuccess {
        data {
          success
        }
      }
    }
  }
`;

export const LOGIN_2FA_VERIFY = gql`
  mutation Login2FAVerify($input: MutationLogin2FAVerifyInput!) {
    login2FAVerify(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on ValidationErrors {
        fields {
            field
            messages
        }
        message
      }
      ... on MutationLogin2FAVerifySuccess {
        data {
          token
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($input: MutationLoginInput!) {
    login(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on ValidationErrors {
        fields {
            field
            messages
        }
        message
      }
      ... on MutationLoginSuccess {
        data {
          token
        }
      }
    }
  }
`;
