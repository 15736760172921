import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { clearToasts, showToaster } from "../../features/toaster/toasterSlice";
import classNames from "classnames";
import { Transition } from '@headlessui/react'

export const Toast = () => {
    const state = useSelector((state) => state.toaster);
    const dispatch = useDispatch();

    useEffect(() => {

        if (state.show) {
            setTimeout(() => {
                dispatch(showToaster(false));

                // We need the fade out after 5 seconds then clear toasts
                setTimeout(() => {
                    dispatch(clearToasts());
                }, 500);
            }, 5000);

        }

    }, [state.show])

    return (
        <Transition
            show={state.show}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 -translate-y-2"
            enterTo="transform opacity-100"
            leave="transition ease-out duration-500"
            leaveFrom="transform translate-x-0 opacity-100"
            leaveTo="transform translate-x-full opacity-0"
        >
        <div className={classNames("Toast z-[107159] fixed sm:min-w-[18em] top-2 sm:max-w-lg sm:left-1/2 sm:-translate-x-1/2 m-4 overflow-x-hidden", {
            '---hidden': !state.toasts.length
        })}>

            { state.toasts.map((toast, index) => (
                
                <figure
                    role="button"
                    onClick={() => dispatch(showToaster(false))}
                    key={ toast.id }>

                        <div className={classNames('bg-gray-900 bg-gradient-to-r text-white p-3 rounded mb-3 shadow-lg flex items-center', {
                            'from-indigo-500 to-indigo-600': toast.type === 'info',
                            'from-digital-teal-500 to-digital-teal-600 bg-green-500': toast.type === 'success',
                            'from-orange-400 to-orange-500': toast.type === 'warning',
                            'from-legacy-blue-400 to-legacy-blue-500': toast.type === 'error',
                        })}>

                            <svg
                                className={classNames("w-6 h-6 mr-2", {
                                    'hidden': toast.type != 'info'
                                })}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                    clipRule="evenodd"
                                />
                            </svg>

                            <svg
                                className={classNames("w-6 h-6 mr-2", {
                                    'hidden': toast.type != 'success'
                                })}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                />
                            </svg>

                            <svg
                                className={classNames("w-6 h-6 mr-2", {
                                    'hidden': toast.type != 'warning'
                                })}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                />
                            </svg>

                            <svg
                                className={classNames("w-6 h-6 mr-2", {
                                    'hidden': toast.type != 'error'
                                })}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                    clipRule="evenodd"
                                />
                            </svg>

                            <div>{ toast.message }</div>
                        </div>

                </figure>

            ))}

        </div>
        </Transition>
    )
}