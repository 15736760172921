import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import createAxiosInstance  from '../../utilities/axios';
import NotificationItem from './NotificationItem';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default forwardRef(function Notifications(props: any, ref: any) {
    const { open, onAction } = props;
    const [items, setItems] =  useState<any>([]);
    const [error, setError] =  useState(false)

    const klass = 'pt-[55px] translate-x-0 -transform top-0 right-0 w-72 shadow-lg bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30';


    const close = (event: any) => {
        if (!ref.current.contains(event.target) && open) {
            onAction(false)
        }
    };

    /**
     * Listen for document click then close sidebar.
     * 
     */
    useEffect(() => {
       document.addEventListener('click', close)

       return () => document.removeEventListener('click', close)
    }, [open]);

   ;

    const getNotifications = async () => {
        const Api = createAxiosInstance(process.env.REACT_APP_V1_HOST);
    
        try {
            const { data } = await Api.get('/api/v2-notifications');
            console.log(data)
            setItems(data);
        } catch (error) {
            console.log("Error getting notifications", error)
            setError(true)
        }
    }

    // Should always fetch when open
    useEffect(() => {
       if (open) {
           getNotifications()
       }
    }, [open]);

    if (error) return;
    
    /**
     * Notifications aside markup.
     * 
     * @returns {node} HTML markup
     */
    const markup = () => {
        return (
            <aside className={classnames(klass, {
                'translate-x-full': !open
            }) } >
                <header className='p-3 border-b relative'>
                    <h4 className='font-medium text-xl'>Notifications</h4>
                    <button 
                        onClick={() => onAction(!open)}
                        aria-label="Close sidebar"
                        className='absolute top-3 right-2 w-7 h-7 rounded-full flex items-center justify-center hover:bg-gray-100 focus:ring-2 focus:ring-gray-200'>
                            <XMarkIcon className='w-5 h-5' />
                        </button>
                </header>
                <div className='divide-y'>
                    { items.info?.original?.map((notification: any) => <NotificationItem key={ notification.unique_id } {...notification } />) }
                </div>
            </aside>
        )
    }

    return markup()
})