import { ForumTopic } from "./ForumTopic"

export const ForumTopics = () => {
    return (
        <section className="ForumTopics border rounded bg-white">
            <header className="px-4 py-3 bg-gray-200 text-sm font-medium"><h2>Topics</h2></header>
            <div className="divide-y px-4">
                <ForumTopic />
                <ForumTopic />
                <ForumTopic />
                <ForumTopic />
            </div>
        </section>
    )   
}