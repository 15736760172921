import axios from 'axios';
import Cookies from 'js-cookie';

const createAxiosInstance = (baseUrl = process.env.REACT_APP_WEBHOOKS_HOST, contentType = 'application/json') => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': contentType,
      //'Content-Type': '`multipart/form-data',
      'Authorization': `Bearer ${Cookies.get('token')}`
    },
    withCredentials: false
  });
};

export default createAxiosInstance;
