import { ForumTableItem } from "./ForumTableItem"

interface ForumTableProps {
    questions: any[]
}

export const ForumTable = (props: ForumTableProps) => {
    const { questions } = props;

    return (
        <div className="ForumTable">
            
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                               
                            </th>
                            <th scope="col" className="px-6 py-3">
                                {/* Some avatars: poster first then however answers limited to 3 etc */}
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Answers
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Latest by
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {questions.map( q => <ForumTableItem key={q.id} question={q} /> )}
                        
                    </tbody>
                </table>
            </div>

        </div>
    )
}