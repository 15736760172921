import React, { useState, useRef } from 'react';
import { ChartBarIcon } from '@heroicons/react/20/solid';

interface IFrameProps {
  reportUrl: string;
  title: string;
}

export default function IFrame(props: IFrameProps) {
    const { reportUrl, title } = props;

    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleFullScreenToggle = () => {
        const iframe: any = iframeRef.current;
    
        if (iframe) {
            if (!isFullScreen) {
              if (iframe.requestFullscreen) {
                iframe.requestFullscreen();
              } else if (iframe.mozRequestFullScreen) {
                iframe.mozRequestFullScreen();
              } else if (iframe.webkitRequestFullscreen) {
                iframe.webkitRequestFullscreen();
              } else if (iframe.msRequestFullscreen) {
                iframe.msRequestFullscreen();
              }
              setIsFullScreen(true);
            } else {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              }
              setIsFullScreen(false);
            }
          }
      };

    // Check for fullscreen change event
    document.addEventListener('fullscreenchange', () => {
        setIsFullScreen(!!document.fullscreenElement);
    });

    if (!reportUrl) return;
    

    return (
        <section className="PowerBi bg-white relative overflow-hidden rounded-xl">
            <header className="p-3 flex items-center justify-between">
                <h5 className="text-lg font-semibold">{ title }</h5>
                <div className='flex items-center gap-x-2'>
                    <ChartBarIcon className='w-5 h-5' />
                    <button onClick={handleFullScreenToggle} className="text-sm font-medium px-3 py-1 bg-yellow-300 rounded-full">View</button>
                </div>
            </header>
            <iframe
                ref={iframeRef}
                title="Microsoft Power BI" width="100%" height="300" src={reportUrl } allowFullScreen={ true }>
                Your browser does not support iframes
            </iframe>
        </section>
    )
}