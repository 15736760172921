// slice reducer
import { createSlice } from '@reduxjs/toolkit'

export const trashFolderIdSlice = createSlice({
  name: 'trashFolderId',
  initialState: {
    id: null,
  },
  reducers: {
    setTrashFolderId: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.id = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTrashFolderId } = trashFolderIdSlice.actions

export default trashFolderIdSlice.reducer