import React     from 'react'
import PropTypes from 'prop-types'

interface TextBadgeProps
{
  children: React.ReactNode;
  color?: string;
  text?: string;
  classes?: string;
}

const TextBadge: React.FC<TextBadgeProps> = ( props ) => {
  const backgroundColor = props.color ?? 'blue-primary'
  const textColor       = props.text ?? 'black'
  const classes = props.classes ?? ''

  const classString = `inline-flex items-center rounded-md bg-${ backgroundColor } p-1 whitespace-nowrap text-xxs font-medium text-${ textColor } leading-none ${ classes }`

  return (
    <>
      <span className={ classString }>{ props.children }</span>
    </>
  )
}

TextBadge.propTypes = {
  children: PropTypes.oneOfType( [
                                   PropTypes.arrayOf( PropTypes.node ),
                                   PropTypes.node
                                 ] ).isRequired,
  color:    PropTypes.string,
  text:     PropTypes.string,
  classes: PropTypes.string
}

export default TextBadge