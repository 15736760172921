import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        threads: {
          merge(existing = [], incoming) {
            // Merge the existing and incoming arrays of threads
            const merged = [...existing];

            // Add only the incoming threads that are not already in the existing array
            incoming.forEach((thread) => {
              if (!existing.some((existingThread) => existingThread.__ref === thread.__ref)) {
                merged.push(thread);
              }
            });

            return merged;
          },
        },
      },
    },
  },
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      console.log(extensions)
      
      //window.location.href = '/login';

      if (extensions && extensions.code === 'UNAUTHENTICATED') {
        // Handle 401 error here
        // You can redirect the user, trigger a logout, or perform any other actions
        // For example, you can redirect the user to the login page
        // window.location.href = '/login';
      }
    });
  }

  if (networkError) {
    // Handle network errors here
    console.error('Network Error:', networkError);
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const link = authLink.concat(httpLink);
export const client = new ApolloClient({
  link: errorLink.concat(link),
  cache
});