// slice reducer
import { createSlice } from '@reduxjs/toolkit'

export const subjectRenameSlice = createSlice({
  name: 'subjectRename',
  initialState: {
    open: false,
  },
  reducers: {
    setRenameSubjectOpen: (state, action) => {
      state.open = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setRenameSubjectOpen } = subjectRenameSlice.actions

export default subjectRenameSlice.reducer