import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Pagination      from '../../../components/Pagination/Pagination'
import FolderTableItem from './FolderTableItem'

export default function FolderTable( props: any ): JSX.Element {
  const { folderId }  = props
  const navigate: any = useNavigate()
  const readIds: any  = []
  const currentUser   = useSelector( ( state: any ) => state.currentUser.user )
  const pagination    = useSelector( ( state: any ) => state.documents.pagination )
  const documents     = useSelector( ( state: any ) => state.documents.data ).filter( ( item: any ) => currentUser.admin
                                                                                                       ? item.parent_id === folderId
                                                                                                       : item.parent_id === folderId && !item.draft && item.publish )

  const setCurrentPage = ( page: number = 1 ) => folderId
                                                 ? navigate( `/documents/folder/${ page }/${ folderId }` )
                                                 : navigate( `/documents/${ page }` )

  return (
    <div>
      { documents.length < 1 && <p>This folder is empty.</p> }

      { documents.length > 0 &&
        <table className="documents-table w-full text-sm">
          <thead>
            <tr>
              <th className="w-[1%] border-b-2 border-gray-table-border p-3 px-0"></th>
              <th className="w-[90%] border-b-2 border-gray-table-border p-3 align-bottom text-left">Name</th>
              <th className="w-[9%] border-b-2 border-gray-table-border p-3"></th>
            </tr>
          </thead>
          <tbody>
            {
              documents.map( ( item: any, idx: any ) =>
                               <FolderTableItem
                                 key={ item.id }
                                 isNew={ readIds && ( readIds.length === 0 || !readIds.includes( item.id ) ) }
                                 itemDetails={ item }
                                 idx={ idx }
                               /> )
            }
          </tbody>
        </table>
      }

      { ( pagination?.totalPages > 0 ) &&
        <Pagination
          className="justify-content-center"
          currentPage={ pagination.page }
          totalCount={ pagination.totalResults }
          pageSize={ pagination.perPage }
          onPageChange={ ( page: any ) => setCurrentPage( page ) }
        />
      }
    </div>
  )
}
