import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, memo } from 'react';
import { Button } from '../Button';
import { XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

// TODO: Modal should not have a fixed padding. Leave that for the content props.
type ModalProps = {
  fit?: boolean;
  isOpen: boolean;
  expanded?: boolean;
  maxHeight?: string
  top?: boolean;
  flush?: boolean;
  openModal?: () => void;
  onClose?: () => void;
  content: React.ReactNode;
};

export const Modal = memo((props: ModalProps) => {
  const { top, flush, maxHeight } = props

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className={classNames("fixed inset-0 overflow-y-auto", {
            'top-14': top
          })}>
            <div className={classNames("flex min-h-full justify-center p-4 text-center", {
              "items-center": !top
            })}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                
                <Dialog.Panel 
                  style={{
                     minWidth: props.expanded ? '70vw' : '', 
                     minHeight: props.expanded ? '70vh' : '',
                     maxHeight: props.maxHeight
                  }} 
                  className={classNames("flex flex-col min-w-content --overflow-y-auto max-w-screen-md transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all", {
                    'w-fit': props.fit,
                    'w-full': !props.fit,
                    'p-6': !flush,
                    'max-h-[calc(80vh_-_55px)]': !maxHeight
                  })} >
                  { props.onClose ? (
                    <header className='relative'>
                      <button onClick={props.onClose} className='text-gray-600 absolute border-none -right-2 -top-2 w-6 h-6 rounded-full flex items-center justify-center bg-gray-100'>
                        <XMarkIcon />
                      </button>
                    </header>
                  ) : null}
                  { props.content }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
})
